/* eslint-disable */
import gql from "graphql-tag";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
    DateTime: { input: any; output: any };
};

export type AcceptanceRateInfo = {
    rate?: Maybe<Scalars["Float"]["output"]>;
};

export type Assignation = {
    _id: Scalars["String"]["output"];
    acceptationNote?: Maybe<Scalars["String"]["output"]>;
    comboAssignationOfferId?: Maybe<Scalars["String"]["output"]>;
    createdAt: Scalars["DateTime"]["output"];
    orderDepartureAt: Scalars["DateTime"]["output"];
    orderId: Scalars["String"]["output"];
    subsidyIds: Array<Scalars["String"]["output"]>;
    userId: Scalars["String"]["output"];
    vehicleId?: Maybe<Scalars["String"]["output"]>;
    vehicleType: Scalars["Int"]["output"];
};

export type AssignationOffer = {
    _id: Scalars["String"]["output"];
    additionalLuggagePairs: Scalars["Int"]["output"];
    adults: Scalars["Int"]["output"];
    allowedDriverCountryIds?: Maybe<Array<Scalars["String"]["output"]>>;
    childSeats?: Maybe<Array<ChildSeatType>>;
    children: Scalars["Int"]["output"];
    claimedPosition: Scalars["Int"]["output"];
    claims: Array<AssignationOfferClaim>;
    claimsCount: Scalars["Int"]["output"];
    createdAt: Scalars["DateTime"]["output"];
    currency: Scalars["Int"]["output"];
    customerNote: Scalars["String"]["output"];
    departureAt: Scalars["DateTime"]["output"];
    departureAtUtc: Scalars["DateTime"]["output"];
    departureInMinutes: Scalars["Float"]["output"];
    destinationCountry: Country;
    destinationCountryId: Scalars["String"]["output"];
    destinationLocation: Location;
    destinationLocationId: Scalars["String"]["output"];
    destinationLocationName: Scalars["String"]["output"];
    destinationTimezone: Scalars["String"]["output"];
    driverNote: Scalars["String"]["output"];
    /** Returns true if the offers is accepted by the current user. */
    isAccepted: Scalars["Boolean"]["output"];
    /** Returns true if the offers is confirmed by the current user. */
    isConfirmed: Scalars["Boolean"]["output"];
    isValid: Scalars["Boolean"]["output"];
    luggage: Luggage;
    note?: Maybe<Scalars["String"]["output"]>;
    orderId: Scalars["String"]["output"];
    orderStatus: OrderStatus;
    originCountry: Country;
    originCountryId: Scalars["String"]["output"];
    originLocation: Location;
    originLocationId: Scalars["String"]["output"];
    originLocationName: Scalars["String"]["output"];
    originTimezone: Scalars["String"]["output"];
    pushNotificationIds: Array<Scalars["String"]["output"]>;
    requiredCapacity: Scalars["Int"]["output"];
    /** Returns true if the invoicing info for assignation offer should be displayed. */
    shouldShowInvoicingInfo: Scalars["Boolean"]["output"];
    status: AssignationOfferStatus;
    stops: Array<AssignationOfferStop>;
    subsidy?: Maybe<Scalars["Float"]["output"]>;
    suggestedBiddingPrice?: Maybe<Scalars["Float"]["output"]>;
    totalPrice: Price;
    tripCountryIds: Array<Scalars["String"]["output"]>;
    type: AssignationOfferType;
    vehicleType: VehicleType;
};

export type AssignationOfferClaim = {
    _id: Scalars["String"]["output"];
    acceptedAt?: Maybe<Scalars["DateTime"]["output"]>;
    assignationOfferId: Scalars["String"]["output"];
    biddingPrice?: Maybe<Scalars["Float"]["output"]>;
    cancelledAt?: Maybe<Scalars["DateTime"]["output"]>;
    confirmedAt?: Maybe<Scalars["DateTime"]["output"]>;
    createdAt: Scalars["DateTime"]["output"];
    declineReason?: Maybe<Scalars["String"]["output"]>;
    declinedAt?: Maybe<Scalars["DateTime"]["output"]>;
    driver: User;
    driverUserId: Scalars["String"]["output"];
    rentalVehicle?: Maybe<RentalVehicle>;
    status: Scalars["Int"]["output"];
    user: User;
    userId: Scalars["String"]["output"];
    vehicleId?: Maybe<Scalars["String"]["output"]>;
};

export type AssignationOfferInput = {
    additionalLuggagePairs: Scalars["Int"]["input"];
    adults: Scalars["Int"]["input"];
    children: Scalars["Int"]["input"];
    currency: Scalars["Int"]["input"];
    departureAt: Scalars["DateTime"]["input"];
    destinationLocationId: Scalars["String"]["input"];
    note?: InputMaybe<Scalars["String"]["input"]>;
    orderId: Scalars["String"]["input"];
    originLocationId: Scalars["String"]["input"];
    stops?: InputMaybe<Array<AssignationOfferStopInput>>;
    subsidy?: InputMaybe<Scalars["Float"]["input"]>;
    suggestedBiddingPrice?: InputMaybe<Scalars["Float"]["input"]>;
    totalPrice: PriceInput;
    type: AssignationOfferType;
    vehicleIndex: Scalars["Int"]["input"];
    vehicleType: VehicleType;
};

export enum AssignationOfferStatus {
    Cancelled = "Cancelled",
    Confirmed = "Confirmed",
    Created = "Created",
    Declined = "Declined",
}

export type AssignationOfferStop = {
    duration: Scalars["Int"]["output"];
    locationId?: Maybe<Scalars["String"]["output"]>;
    locationName: Scalars["String"]["output"];
};

export type AssignationOfferStopInput = {
    duration: Scalars["Int"]["input"];
    locationId?: InputMaybe<Scalars["String"]["input"]>;
};

export enum AssignationOfferType {
    Bidding = "Bidding",
    FixedPrice = "FixedPrice",
}

export type AvailabilityDayInput = {
    date: Scalars["DateTime"]["input"];
    dayStatus: AvailabilityDayStatus;
};

export enum AvailabilityDayStatus {
    Active = "Active",
    Assigned = "Assigned",
    Empty = "Empty",
    Inactive = "Inactive",
}

export enum BillingInformationValidationStatus {
    MissingActiveBillingInformation = "MissingActiveBillingInformation",
    MissingBillingInformation = "MissingBillingInformation",
    MissingDocuments = "MissingDocuments",
    MissingMangopayInformation = "MissingMangopayInformation",
    Valid = "Valid",
    WaitingApproval = "WaitingApproval",
}

export enum ChildSeatType {
    Booster = "Booster",
    BoosterSeat = "BoosterSeat",
    ForwardFacing = "ForwardFacing",
    RearFacing = "RearFacing",
}

export type ComboAssignationOffer = {
    _id: Scalars["String"]["output"];
    allowedDriverCountryIds: Array<Scalars["String"]["output"]>;
    childSeats: Array<ChildSeatType>;
    claims: Array<AssignationOfferClaim>;
    createdAt: Scalars["DateTime"]["output"];
    currency: Scalars["Int"]["output"];
    departureAt: Scalars["DateTime"]["output"];
    departureAtUtc: Scalars["DateTime"]["output"];
    departureInMinutes: Scalars["Float"]["output"];
    /** Returns true if the combo offer is accepted by the current user. */
    isAccepted: Scalars["Boolean"]["output"];
    isClaimed?: Maybe<Scalars["Boolean"]["output"]>;
    /** Returns true if the combo offer is confirmed by the current user. */
    isConfirmed: Scalars["Boolean"]["output"];
    isMultiday: Scalars["Boolean"]["output"];
    /** Returns true if the combo offer is personal. */
    isPersonal: Scalars["Boolean"]["output"];
    note?: Maybe<Scalars["String"]["output"]>;
    requiredCapacity: Scalars["Float"]["output"];
    /** Returns true if the invoicing info for combo offer should be displayed. */
    shouldShowInvoicingInfo: Scalars["Boolean"]["output"];
    status: AssignationOfferStatus;
    subsidy?: Maybe<Scalars["Float"]["output"]>;
    totalPrice: Price;
    tripCountries: Array<Country>;
    tripCountryIds: Array<Scalars["String"]["output"]>;
    trips: Array<ComboAssignationOfferTrip>;
    vehicleType: VehicleType;
};

export type ComboAssignationOfferTrip = {
    additionalLuggagePairs: Scalars["Int"]["output"];
    adults: Scalars["Int"]["output"];
    arrivalAt: Scalars["DateTime"]["output"];
    assignationId?: Maybe<Scalars["String"]["output"]>;
    childSeats?: Maybe<Array<ChildSeatType>>;
    children: Scalars["Int"]["output"];
    customerNote: Scalars["String"]["output"];
    departureAt: Scalars["DateTime"]["output"];
    departureAtUtc: Scalars["DateTime"]["output"];
    departureInMinutes: Scalars["Float"]["output"];
    destinationCountry: Country;
    destinationCountryId: Scalars["String"]["output"];
    destinationLocationId: Scalars["String"]["output"];
    destinationLocationName: Scalars["String"]["output"];
    destinationTimezone: Scalars["String"]["output"];
    driverNote: Scalars["String"]["output"];
    luggage: Luggage;
    orderId: Scalars["String"]["output"];
    originCountry: Country;
    originCountryId: Scalars["String"]["output"];
    originLocationId: Scalars["String"]["output"];
    originLocationName: Scalars["String"]["output"];
    originTimezone: Scalars["String"]["output"];
    stops: Array<AssignationOfferStop>;
    transferFromPreviousDestination?: Maybe<TransferFromPreviousDestination>;
};

export type ComboOrAssignationOffer = {
    assignationOffer?: Maybe<AssignationOffer>;
    comboAssignationOffer?: Maybe<ComboAssignationOffer>;
};

export type CooperationAgreementStatus = {
    status: DriverCooperationAgreementStatus;
};

export type Country = {
    _id: Scalars["String"]["output"];
    englishName: Scalars["String"]["output"];
    isoCode: Scalars["String"]["output"];
};

export type CreateDynamicFormConfigInput = {
    countryId?: InputMaybe<Scalars["String"]["input"]>;
    entityType: DynamicFormConfigEntityType;
    entityTypeLegalName: Scalars["String"]["input"];
    fields: Array<DynamicFormConfigFieldInput>;
    formType: DynamicFormConfigFormType;
};

export type CrossBorderTripWhitelistEntry = {
    additionalFee?: Maybe<Scalars["Float"]["output"]>;
    additionalPrice?: Maybe<Scalars["Float"]["output"]>;
    countryIsoA: Scalars["String"]["output"];
    countryIsoB: Scalars["String"]["output"];
    preferredPricing?: Maybe<Scalars["String"]["output"]>;
};

export type CrossBorderTripWhitelistEntryInput = {
    additionalFee?: InputMaybe<Scalars["Float"]["input"]>;
    additionalPrice?: InputMaybe<Scalars["Float"]["input"]>;
    countryIsoA: Scalars["String"]["input"];
    countryIsoB: Scalars["String"]["input"];
    preferredPricing?: InputMaybe<Scalars["String"]["input"]>;
};

export enum Currency {
    AustralianDollar = "AustralianDollar",
    BritishPound = "BritishPound",
    CanadianDollar = "CanadianDollar",
    CzechKoruna = "CzechKoruna",
    Dollar = "Dollar",
    Euro = "Euro",
    MexicanPeso = "MexicanPeso",
    NewZealandDollar = "NewZealandDollar",
}

export type DiscountOnStopsInput = {
    discountedStopsCount: Scalars["Int"]["input"];
    minimalCountOfStopsBooked: Scalars["Int"]["input"];
};

export type Driver = {
    acceptanceRateInfo?: Maybe<AcceptanceRateInfo>;
    address: Scalars["String"]["output"];
    driverCompanyUser?: Maybe<User>;
    position?: Maybe<GeoPoint>;
    score: Score;
};

export enum DriverAppUpdateAction {
    ForceUpdate = "FORCE_UPDATE",
    NoUpdate = "NO_UPDATE",
    OptionalUpdate = "OPTIONAL_UPDATE",
}

export type DriverAppVersion = {
    latestVersion: Scalars["String"]["output"];
    updateAction: DriverAppUpdateAction;
};

export enum DriverCooperationAgreementStatus {
    HasActive = "HAS_ACTIVE",
    HasNoneOrDeclined = "HAS_NONE_OR_DECLINED",
    HasNonDeclined = "HAS_NON_DECLINED",
}

export type DriverCountryRule = {
    canAcceptAssignationOffers: Scalars["Boolean"]["output"];
    canBeAutomaticallyAssigned: Scalars["Boolean"]["output"];
    destinationCountry: Country;
    destinationCountryId: Scalars["String"]["output"];
    driverCountry: Country;
    driverCountryId: Scalars["String"]["output"];
    originCountry: Country;
    originCountryId: Scalars["String"]["output"];
};

export type DriverCountryRuleInput = {
    canAcceptAssignationOffers: Scalars["Boolean"]["input"];
    canBeAutomaticallyAssigned: Scalars["Boolean"]["input"];
    destinationCountryId: Scalars["String"]["input"];
    driverCountryId: Scalars["String"]["input"];
    originCountryId: Scalars["String"]["input"];
};

export type DriverCountryRuleUpdateInput = {
    canAcceptAssignationOffers?: InputMaybe<Scalars["Boolean"]["input"]>;
    canBeAutomaticallyAssigned?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DriverFaq = {
    _id: Scalars["String"]["output"];
    answer: Scalars["String"]["output"];
    category: Scalars["String"]["output"];
    question: Scalars["String"]["output"];
};

export type DriversCompany = {
    acceptanceRateInfo?: Maybe<AcceptanceRateInfo>;
    address: Scalars["String"]["output"];
    name: Scalars["String"]["output"];
    position?: Maybe<GeoPoint>;
    score: Score;
};

export enum DynamicFormConfigEntityType {
    Driver = "DRIVER",
    DriverCompany = "DRIVER_COMPANY",
}

export type DynamicFormConfigFieldInput = {
    englishName: Scalars["String"]["input"];
    fieldName: DynamicFormConfigFieldName;
    isRequired: Scalars["Boolean"]["input"];
    localName: Scalars["String"]["input"];
    order: Scalars["Int"]["input"];
    placeholder: Scalars["String"]["input"];
};

export enum DynamicFormConfigFieldName {
    AbaRouting = "abaRouting",
    AccountNumber = "accountNumber",
    Address = "address",
    AnnualIncomeRangeInEuro = "annualIncomeRangeInEuro",
    BankAccountCountryId = "bankAccountCountryId",
    BankAccountHolderName = "bankAccountHolderName",
    BasedIn = "basedIn",
    BicSwift = "bicSwift",
    BirthdayAt = "birthdayAt",
    Birthplace = "birthplace",
    BirthplaceCity = "birthplaceCity",
    BirthplaceCountryId = "birthplaceCountryId",
    BusinessAddressCity = "businessAddressCity",
    BusinessAddressDistrictName = "businessAddressDistrictName",
    BusinessAddressFloorIdentifier = "businessAddressFloorIdentifier",
    BusinessAddressHouseNumber = "businessAddressHouseNumber",
    BusinessAddressPob = "businessAddressPOB",
    BusinessAddressStreet = "businessAddressStreet",
    BusinessAddressSuiteIdentifier = "businessAddressSuiteIdentifier",
    BusinessAddressZip = "businessAddressZIP",
    BusinessName = "businessName",
    CompanyIdNumber = "companyIdNumber",
    CorrespondenceAddress = "correspondenceAddress",
    CorrespondenceAddressCity = "correspondenceAddressCity",
    CorrespondenceAddressDistrictName = "correspondenceAddressDistrictName",
    CorrespondenceAddressFloorIdentifier = "correspondenceAddressFloorIdentifier",
    CorrespondenceAddressHouseNumber = "correspondenceAddressHouseNumber",
    CorrespondenceAddressIsDifferentFromHqAddress = "correspondenceAddressIsDifferentFromHqAddress",
    CorrespondenceAddressPob = "correspondenceAddressPOB",
    CorrespondenceAddressStreet = "correspondenceAddressStreet",
    CorrespondenceAddressSuiteIdentifier = "correspondenceAddressSuiteIdentifier",
    CorrespondenceAddressZip = "correspondenceAddressZIP",
    Email = "email",
    FirstName = "firstName",
    HeadquartersAddress = "headquartersAddress",
    HeadquartersAddressCity = "headquartersAddressCity",
    HeadquartersAddressDistrictName = "headquartersAddressDistrictName",
    HeadquartersAddressFloorIdentifier = "headquartersAddressFloorIdentifier",
    HeadquartersAddressHouseNumber = "headquartersAddressHouseNumber",
    HeadquartersAddressPob = "headquartersAddressPOB",
    HeadquartersAddressStreet = "headquartersAddressStreet",
    HeadquartersAddressSuiteIdentifier = "headquartersAddressSuiteIdentifier",
    HeadquartersAddressZip = "headquartersAddressZIP",
    Iban = "iban",
    IdentifiedPersonNumber = "identifiedPersonNumber",
    InvoiceCountryId = "invoiceCountryId",
    LastName = "lastName",
    NationalityCountryId = "nationalityCountryId",
    Occupation = "occupation",
    PermanentEstablishmentAddressCity = "permanentEstablishmentAddressCity",
    PermanentEstablishmentAddressCountryId = "permanentEstablishmentAddressCountryId",
    PermanentEstablishmentAddressDistrictName = "permanentEstablishmentAddressDistrictName",
    PermanentEstablishmentAddressFloorIdentifier = "permanentEstablishmentAddressFloorIdentifier",
    PermanentEstablishmentAddressHouseNumber = "permanentEstablishmentAddressHouseNumber",
    PermanentEstablishmentAddressPob = "permanentEstablishmentAddressPOB",
    PermanentEstablishmentAddressStreet = "permanentEstablishmentAddressStreet",
    PermanentEstablishmentAddressSuiteIdentifier = "permanentEstablishmentAddressSuiteIdentifier",
    PermanentEstablishmentAddressZip = "permanentEstablishmentAddressZIP",
    PermanentEstablishmentInAnotherCountry = "permanentEstablishmentInAnotherCountry",
    ResidenceCountryId = "residenceCountryId",
    TaxIdentificationNumber = "taxIdentificationNumber",
    TaxResidenceCountryId = "taxResidenceCountryId",
    TransportCountries = "transportCountries",
    UboDeclaration = "uboDeclaration",
    VatNumber = "vatNumber",
}

export type DynamicFormConfigFieldType = {
    englishName: Scalars["String"]["output"];
    fieldName: Scalars["String"]["output"];
    isRequired: Scalars["Boolean"]["output"];
    localName: Scalars["String"]["output"];
    order: Scalars["Int"]["output"];
    placeholder: Scalars["String"]["output"];
};

export type DynamicFormConfigFindOneInput = {
    countryId?: InputMaybe<Scalars["String"]["input"]>;
    entityType: DynamicFormConfigEntityType;
    formType: DynamicFormConfigFormType;
};

export enum DynamicFormConfigFormType {
    BillingInformation = "BILLING_INFORMATION",
    CooperationAgreement = "COOPERATION_AGREEMENT",
}

export type DynamicFormConfigTypeGraphQlType = {
    _id: Scalars["String"]["output"];
    countryId?: Maybe<Scalars["String"]["output"]>;
    entityType: DynamicFormConfigEntityType;
    entityTypeLegalName: Scalars["String"]["output"];
    fields: Array<DynamicFormConfigFieldType>;
    formType: DynamicFormConfigFormType;
};

export type EarlyOfferAccessGqlType = {
    hasValidEarlyOfferSubscription: Scalars["Boolean"]["output"];
};

export type EnabledRegionInput = {
    coordinateA: GeoPointInput;
    coordinateB: GeoPointInput;
};

export type EnabledRegionType = {
    coordinateA: GeoPoint;
    coordinateB: GeoPoint;
};

export type EnabledRegions = {
    regions: Array<EnabledRegionType>;
};

export type EnabledRegionsInput = {
    regions: Array<EnabledRegionInput>;
};

export type FeatureFlagKeyValue = {
    key: Scalars["String"]["output"];
    value: Scalars["Boolean"]["output"];
};

export enum FlexibleOffersRuntimeConfigKeys {
    AerialDistanceToDriverSearchRadiusPerc = "aerialDistanceToDriverSearchRadiusPerc",
    AerialDistanceToMidpointDriverSearchRadiusPerc = "aerialDistanceToMidpointDriverSearchRadiusPerc",
    DestinationLocationDriverSearchRadius = "destinationLocationDriverSearchRadius",
    EnabledRegions = "enabledRegions",
    MaxAllowedAerialDistance = "maxAllowedAerialDistance",
    MaxAllowedRoadDistance = "maxAllowedRoadDistance",
    MinAllowedAerialDistance = "minAllowedAerialDistance",
    MinAllowedRoadDistance = "minAllowedRoadDistance",
    MinAvailableDriversCount = "minAvailableDriversCount",
    OriginLocationDriverSearchRadius = "originLocationDriverSearchRadius",
}

export enum FlexibleOffersRuntimeConfigScalarKeys {
    AerialDistanceToDriverSearchRadiusPerc = "aerialDistanceToDriverSearchRadiusPerc",
    AerialDistanceToMidpointDriverSearchRadiusPerc = "aerialDistanceToMidpointDriverSearchRadiusPerc",
    DestinationLocationDriverSearchRadius = "destinationLocationDriverSearchRadius",
    MaxAllowedAerialDistance = "maxAllowedAerialDistance",
    MaxAllowedRoadDistance = "maxAllowedRoadDistance",
    MinAllowedAerialDistance = "minAllowedAerialDistance",
    MinAllowedRoadDistance = "minAllowedRoadDistance",
    MinAvailableDriversCount = "minAvailableDriversCount",
    OriginLocationDriverSearchRadius = "originLocationDriverSearchRadius",
}

export type FlexibleOffersRuntimeConfigType = {
    aerialDistanceToDriverSearchRadiusPerc: Scalars["Float"]["output"];
    aerialDistanceToMidpointDriverSearchRadiusPerc: Scalars["Float"]["output"];
    crossBorderTripWhitelist: Array<CrossBorderTripWhitelistEntry>;
    destinationLocationDriverSearchRadius: Scalars["Float"]["output"];
    enabledRegions: EnabledRegions;
    maxAllowedAerialDistance: Scalars["Float"]["output"];
    maxAllowedRoadDistance: Scalars["Float"]["output"];
    minAllowedAerialDistance: Scalars["Float"]["output"];
    minAllowedRoadDistance: Scalars["Float"]["output"];
    minAvailableDriversCount: Scalars["Float"]["output"];
    originLocationDriverSearchRadius: Scalars["Float"]["output"];
    prohibitedRegions: ProhibitedRegions;
};

export type FlexibleOffersRuntimeConfigValueEnabledRegions = {
    value: EnabledRegions;
};

export type FlexibleOffersRuntimeConfigValueNumber = {
    value: Scalars["Float"]["output"];
};

export type FlexibleOffersRuntimeConfigValues =
    | FlexibleOffersRuntimeConfigValueEnabledRegions
    | FlexibleOffersRuntimeConfigValueNumber;

export type GeoPoint = {
    latitude: Scalars["Float"]["output"];
    longitude: Scalars["Float"]["output"];
};

export type GeoPointInput = {
    latitude: Scalars["Float"]["input"];
    longitude: Scalars["Float"]["input"];
};

export type LandMassType = {
    _id: Scalars["String"]["output"];
    description: Scalars["String"]["output"];
    polygon: PolygonType;
};

export type LandMassTypeInput = {
    _id: Scalars["String"]["input"];
    description: Scalars["String"]["input"];
    polygon: PolygonTypeInput;
};

export type Location = {
    name: Scalars["String"]["output"];
};

export type Luggage = {
    carryOnCount: Scalars["Int"]["output"];
    checkInCount: Scalars["Int"]["output"];
};

export enum LuggageType {
    CarryOn = "CarryOn",
    Suitcase = "Suitcase",
}

export type Mutation = {
    acceptAssignationOffer: Scalars["Boolean"]["output"];
    acceptComboAssignationOffer: Scalars["Boolean"]["output"];
    acceptPersonalBasicAssignationOffer: Scalars["Boolean"]["output"];
    /** Add an entry into the Cross Border Trip Whitelist */
    addCrossBorderTripWhitelistEntry: Scalars["Boolean"]["output"];
    changeCompanyDriverForTrip: Scalars["Boolean"]["output"];
    changeVehicleForTrip: Scalars["Boolean"]["output"];
    checkMobileVerification: TokenPair;
    /** Use this to update the cross border whitelist Flexible Offers runtime config */
    configEntryPutCrossBorderWhitelist: Scalars["Boolean"]["output"];
    /** Use this to update the enabled regions Flexible Offers runtime config */
    configEntryPutEnabledRegions: Scalars["Boolean"]["output"];
    /** Use this to update scalar Flexible Offers runtime config parameters */
    configEntryPutScalarParameters: Scalars["Boolean"]["output"];
    createAssignationOffer: Scalars["String"]["output"];
    createDiscountCampaign: Scalars["Boolean"]["output"];
    createDiscountCode: Scalars["Boolean"]["output"];
    createDriverCountryRule: Scalars["Boolean"]["output"];
    createDynamicFormConfig: Scalars["String"]["output"];
    declinePersonalBasicAssignationOffer: Scalars["Boolean"]["output"];
    declinePersonalComboAssignationOffer: Scalars["Boolean"]["output"];
    deleteDriverCountryRule: Scalars["Boolean"]["output"];
    deleteDynamicFormConfig: Scalars["Boolean"]["output"];
    emailPasswordAuthentication: TokenPair;
    insertLandMass: Scalars["Boolean"]["output"];
    refreshTokens: TokenPair;
    /** Remove an entry from the Cross Border Trip Whitelist */
    removeCrossBorderTripWhitelistEntry: Scalars["Boolean"]["output"];
    removeUnavailabilitiesForWholeMonth: Scalars["Boolean"]["output"];
    requestResetPassword: Scalars["Boolean"]["output"];
    setPublicOfferPreferences: Scalars["Boolean"]["output"];
    setUnavailabilitiesForWholeMonth: Scalars["Boolean"]["output"];
    startMobileVerification: Scalars["Boolean"]["output"];
    /** Update an entry in the Cross Border Trip Whitelist */
    updateCrossBorderTripWhitelistEntry: Scalars["Boolean"]["output"];
    updateDriverCountryRule: Scalars["Boolean"]["output"];
    updateDynamicFormConfig: DynamicFormConfigTypeGraphQlType;
    updateRide: Scalars["String"]["output"];
    updateUserMultipleAvailabilities: Scalars["Boolean"]["output"];
    upsertDynamicFormConfig: DynamicFormConfigTypeGraphQlType;
};

export type MutationAcceptAssignationOfferArgs = {
    biddingPrice?: InputMaybe<Scalars["Float"]["input"]>;
    companyDriverUserId?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["String"]["input"];
    rentalVehicle?: InputMaybe<RentalVehicleInput>;
    vehicleId?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationAcceptComboAssignationOfferArgs = {
    biddingPrice?: InputMaybe<Scalars["Float"]["input"]>;
    companyDriverUserId?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["String"]["input"];
    rentalVehicle?: InputMaybe<RentalVehicleInput>;
    vehicleId?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationAcceptPersonalBasicAssignationOfferArgs = {
    companyDriverUserId?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["String"]["input"];
    rentalVehicle?: InputMaybe<RentalVehicleInput>;
    vehicleId?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationAddCrossBorderTripWhitelistEntryArgs = {
    crossBorderTripWhitelistEntry: CrossBorderTripWhitelistEntryInput;
};

export type MutationChangeCompanyDriverForTripArgs = {
    driverId: Scalars["String"]["input"];
    tripId: Scalars["String"]["input"];
};

export type MutationChangeVehicleForTripArgs = {
    rentalVehicle?: InputMaybe<RentalVehicleInput>;
    tripId: Scalars["String"]["input"];
    vehicleId?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCheckMobileVerificationArgs = {
    code: Scalars["String"]["input"];
    phoneNumber: Scalars["String"]["input"];
};

export type MutationConfigEntryPutCrossBorderWhitelistArgs = {
    crossCountryWhitelist: Array<CrossBorderTripWhitelistEntryInput>;
    ttl?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationConfigEntryPutEnabledRegionsArgs = {
    enabledRegions: EnabledRegionsInput;
    ttl: Scalars["Float"]["input"];
};

export type MutationConfigEntryPutScalarParametersArgs = {
    RuntimeConfigEntry: RuntimeScalarConfigEntryInput;
};

export type MutationCreateAssignationOfferArgs = {
    input: AssignationOfferInput;
};

export type MutationCreateDiscountCampaignArgs = {
    _id: Scalars["String"]["input"];
    allowedNumberOfUsages?: InputMaybe<Scalars["Int"]["input"]>;
    discountAmount?: InputMaybe<Scalars["Int"]["input"]>;
    discountAmountCurrency?: InputMaybe<Scalars["Int"]["input"]>;
    discountCoefficient?: InputMaybe<Scalars["Float"]["input"]>;
    discountOnStops?: InputMaybe<DiscountOnStopsInput>;
    minOrderPrice?: InputMaybe<Scalars["Float"]["input"]>;
    name: Scalars["String"]["input"];
    reusability: Scalars["String"]["input"];
    type?: InputMaybe<Scalars["String"]["input"]>;
    validCreditCardTypes?: InputMaybe<Array<Scalars["String"]["input"]>>;
    validFrom: Scalars["DateTime"]["input"];
    validPaymentMethods: Array<Scalars["Int"]["input"]>;
    validTo: Scalars["DateTime"]["input"];
};

export type MutationCreateDiscountCodeArgs = {
    _id: Scalars["String"]["input"];
    createdAt: Scalars["DateTime"]["input"];
    discountCampaignId: Scalars["String"]["input"];
    generatedByEmail?: InputMaybe<Scalars["String"]["input"]>;
    orderIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
    usageHistory?: InputMaybe<Array<UsageHistoryArgs>>;
    usedAt?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
    usedByUserId?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type MutationCreateDriverCountryRuleArgs = {
    input: DriverCountryRuleInput;
};

export type MutationCreateDynamicFormConfigArgs = {
    createDynamicFormConfig: CreateDynamicFormConfigInput;
};

export type MutationDeclinePersonalBasicAssignationOfferArgs = {
    id: Scalars["String"]["input"];
    reason: Scalars["String"]["input"];
};

export type MutationDeclinePersonalComboAssignationOfferArgs = {
    id: Scalars["String"]["input"];
    reason?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationDeleteDriverCountryRuleArgs = {
    destinationCountryId: Scalars["String"]["input"];
    driverCountryId: Scalars["String"]["input"];
    originCountryId: Scalars["String"]["input"];
};

export type MutationDeleteDynamicFormConfigArgs = {
    id: Scalars["String"]["input"];
};

export type MutationEmailPasswordAuthenticationArgs = {
    email: Scalars["String"]["input"];
    password: Scalars["String"]["input"];
};

export type MutationInsertLandMassArgs = {
    landMass: LandMassTypeInput;
};

export type MutationRefreshTokensArgs = {
    refreshBoth?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationRemoveCrossBorderTripWhitelistEntryArgs = {
    crossBorderTripWhitelistEntry: CrossBorderTripWhitelistEntryInput;
};

export type MutationRemoveUnavailabilitiesForWholeMonthArgs = {
    month: Scalars["Int"]["input"];
    year: Scalars["Int"]["input"];
};

export type MutationRequestResetPasswordArgs = {
    email: Scalars["String"]["input"];
};

export type MutationSetPublicOfferPreferencesArgs = {
    input: PublicOffersPreferencesInput;
};

export type MutationSetUnavailabilitiesForWholeMonthArgs = {
    month: Scalars["Int"]["input"];
    year: Scalars["Int"]["input"];
};

export type MutationStartMobileVerificationArgs = {
    phoneNumber: Scalars["String"]["input"];
};

export type MutationUpdateCrossBorderTripWhitelistEntryArgs = {
    crossBorderTripWhitelistEntry: CrossBorderTripWhitelistEntryInput;
};

export type MutationUpdateDriverCountryRuleArgs = {
    destinationCountryId: Scalars["String"]["input"];
    driverCountryId: Scalars["String"]["input"];
    originCountryId: Scalars["String"]["input"];
    update: DriverCountryRuleUpdateInput;
};

export type MutationUpdateDynamicFormConfigArgs = {
    updateDynamicFormConfig: UpdateDynamicFormConfigInput;
};

export type MutationUpdateRideArgs = {
    ride: RideInput;
};

export type MutationUpdateUserMultipleAvailabilitiesArgs = {
    availabilityUpdates: Array<AvailabilityDayInput>;
};

export type MutationUpsertDynamicFormConfigArgs = {
    upsertDynamicFormConfigInput: CreateDynamicFormConfigInput;
};

export enum OrderStatus {
    Accepted = "Accepted",
    Cancelled = "Cancelled",
    Confirmed = "Confirmed",
    Declined = "Declined",
    Draft = "Draft",
    Pending = "Pending",
    TimedOut = "TimedOut",
}

export type PaginatedAssignationOfferType = {
    data: Array<AssignationOffer>;
    totalCount: Scalars["Int"]["output"];
};

export type PaginatedDriverCountryRuleType = {
    data: Array<DriverCountryRule>;
    totalCount: Scalars["Int"]["output"];
};

export type PaginatedTripType = {
    data: Array<Trip>;
    totalCount: Scalars["Int"]["output"];
};

export type Passenger = {
    childSeatType?: Maybe<ChildSeatType>;
    firstName?: Maybe<Scalars["String"]["output"]>;
    lastName?: Maybe<Scalars["String"]["output"]>;
    luggage: Array<LuggageType>;
    type: PassengerType;
    userId?: Maybe<Scalars["String"]["output"]>;
};

export enum PassengerType {
    Adult = "Adult",
    Child = "Child",
    Lead = "Lead",
}

export type PersonalAssignationOffer = {
    basic?: Maybe<PersonalBasicAssignationOffer>;
    combo?: Maybe<ComboAssignationOffer>;
    pool?: Maybe<PersonalPoolAssignationOffer>;
};

export type PersonalBasicAssignationOffer = {
    _id: Scalars["String"]["output"];
    adults: Scalars["Int"]["output"];
    childSeats?: Maybe<Array<ChildSeatType>>;
    children: Scalars["Int"]["output"];
    currency: Currency;
    customerNote: Scalars["String"]["output"];
    departureAt: Scalars["DateTime"]["output"];
    departureAtUtc: Scalars["DateTime"]["output"];
    departureInMinutes: Scalars["Float"]["output"];
    destinationCountry: Country;
    destinationCountryId: Scalars["String"]["output"];
    destinationLocation: Location;
    destinationLocationId: Scalars["String"]["output"];
    driverNote: Scalars["String"]["output"];
    luggage: Luggage;
    note?: Maybe<Scalars["String"]["output"]>;
    orderId: Scalars["String"]["output"];
    originCountry: Country;
    originCountryId: Scalars["String"]["output"];
    originLocation: Location;
    originLocationId: Scalars["String"]["output"];
    originTimezone: Scalars["String"]["output"];
    requiredCapacity: Scalars["Int"]["output"];
    /** Returns true if the invoicing info for assignation offer should be displayed. */
    shouldShowInvoicingInfo: Scalars["Boolean"]["output"];
    status: AssignationOfferStatus;
    stops: Array<AssignationOfferStop>;
    subsidy?: Maybe<Scalars["Float"]["output"]>;
    totalPrice: Price;
    tripCountryIds: Array<Scalars["String"]["output"]>;
    vehicleType: VehicleType;
};

export type PersonalPoolAssignationOffer = {
    _id: Scalars["String"]["output"];
    assignationPrice: Scalars["Float"]["output"];
    childSeats?: Maybe<Array<ChildSeatType>>;
    currency: Currency;
    departureAt: Scalars["DateTime"]["output"];
    departureAtUtc: Scalars["DateTime"]["output"];
    departureInMinutes: Scalars["Float"]["output"];
    destinationCountry: Country;
    destinationCountryId: Scalars["String"]["output"];
    destinationLocation: Location;
    destinationLocationId: Scalars["String"]["output"];
    originCountry: Country;
    originCountryId: Scalars["String"]["output"];
    originLocation: Location;
    originLocationId: Scalars["String"]["output"];
    originTimezone: Scalars["String"]["output"];
    status: AssignationOfferStatus;
    vehicleType: VehicleType;
};

export type PolygonType = {
    coordinates: Array<Array<Array<Scalars["Float"]["output"]>>>;
    type: Scalars["String"]["output"];
};

export type PolygonTypeInput = {
    coordinates: Array<Array<Array<Scalars["Float"]["input"]>>>;
    type: Scalars["String"]["input"];
};

export type Price = {
    fee: Scalars["Float"]["output"];
    total: Scalars["Float"]["output"];
};

export type PriceInput = {
    fee?: InputMaybe<Scalars["Float"]["input"]>;
    total?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ProhibitedRegionInput = {
    coordinateA: GeoPointInput;
    coordinateB: GeoPointInput;
};

export type ProhibitedRegionType = {
    coordinateA: GeoPoint;
    coordinateB: GeoPoint;
};

export type ProhibitedRegions = {
    regions: Array<ProhibitedRegionType>;
};

export type ProhibitedRegionsInput = {
    regions: Array<ProhibitedRegionInput>;
};

export type PublicOffersPreferences = {
    location?: Maybe<GeoPoint>;
    locationType: Scalars["Int"]["output"];
};

export type PublicOffersPreferencesInput = {
    location?: InputMaybe<GeoPointInput>;
    locationType: Scalars["Int"]["input"];
};

export type Query = {
    /** Returns public assignation offer by id */
    assignationOffer: AssignationOffer;
    assignationOfferClaim: AssignationOfferClaim;
    assignationOfferClaimByUser: AssignationOfferClaim;
    /** Lists all public assignation offers */
    assignationOffers: PaginatedAssignationOfferType;
    /** Lists public combo and assignation offers near the driver */
    comboAndAssignationOffersNearDriver: Array<ComboOrAssignationOffer>;
    /** Returns combo assignation offer by id */
    comboAssignationOffer: ComboAssignationOffer;
    /** Lists active drivers for the given company. In case of passing vehicleType, only suitable drivers are returned. */
    companyDrivers: Array<User>;
    /** Use this to retrieve Flexible Offers runtime configuration parameters */
    configEntryValue: FlexibleOffersRuntimeConfigValues;
    countriesByEnglishName: Array<Country>;
    currentDriverProfiles: Array<UserProfile>;
    /** Returns currently authenticated user */
    currentUser: User;
    currentUserAssignations: Array<Assignation>;
    currentUserCompletedTrips: PaginatedTripType;
    currentUserPendingAssignationsCount: Scalars["Int"]["output"];
    currentUserPendingTripsForAcceptationCount: Scalars["Int"]["output"];
    currentUserTrip: Trip;
    currentUserUpcomingTrips: PaginatedTripType;
    driverAppVersion: DriverAppVersion;
    driverCountryRule: DriverCountryRule;
    driverCountryRules: PaginatedDriverCountryRuleType;
    driverFAQ: Array<DriverFaq>;
    flexibleOffersGlobalScalarConfig: Array<RuntimeScalarConfigEntry>;
    flexibleOffersRuntimeConfig: FlexibleOffersRuntimeConfigType;
    getAllDynamicFormConfigs: Array<DynamicFormConfigTypeGraphQlType>;
    getCustomerPhoneNumberForOrder: Scalars["String"]["output"];
    getDynamicFormConfig: DynamicFormConfigTypeGraphQlType;
    getDynamicFormConfigById: DynamicFormConfigTypeGraphQlType;
    isAuthorizedForEarlyOffers: EarlyOfferAccessGqlType;
    landMassesForPoint: Array<LandMassType>;
    listLandMasses: Array<LandMassType>;
    personalAssignationOffersForCurrentUser: Array<PersonalAssignationOffer>;
    personalBasicAssignationOffer: PersonalBasicAssignationOffer;
    suitableVehicleModels: Array<VehicleModel>;
    suitableVehicleModelsByAssignationOffer: Array<VehicleModel>;
    suitableVehiclesForCurrentUser: Array<Vehicle>;
    suitableVehiclesForCurrentUserByAssignationOffer: Array<Vehicle>;
    unavailabilities: Array<Unavailability>;
    vehicleMakes: Array<VehicleMake>;
    vehicleModels: Array<VehicleModel>;
    wouldTripRequireWaterTransport: Scalars["Boolean"]["output"];
};

export type QueryAssignationOfferArgs = {
    id: Scalars["String"]["input"];
};

export type QueryAssignationOfferClaimArgs = {
    id: Scalars["String"]["input"];
};

export type QueryAssignationOfferClaimByUserArgs = {
    assignationOfferId: Scalars["String"]["input"];
    userId: Scalars["String"]["input"];
};

export type QueryAssignationOffersArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderId?: InputMaybe<Scalars["String"]["input"]>;
    statusIn?: InputMaybe<Array<AssignationOfferStatus>>;
    vehicleTypes?: InputMaybe<Array<VehicleType>>;
};

export type QueryComboAndAssignationOffersNearDriverArgs = {
    currentLocation?: InputMaybe<GeoPointInput>;
};

export type QueryComboAssignationOfferArgs = {
    id: Scalars["String"]["input"];
};

export type QueryCompanyDriversArgs = {
    companyUserId: Scalars["String"]["input"];
    vehicleType?: InputMaybe<VehicleType>;
};

export type QueryConfigEntryValueArgs = {
    key: FlexibleOffersRuntimeConfigKeys;
};

export type QueryCurrentUserCompletedTripsArgs = {
    limit: Scalars["Int"]["input"];
    offset: Scalars["Int"]["input"];
};

export type QueryCurrentUserTripArgs = {
    tripId: Scalars["String"]["input"];
};

export type QueryCurrentUserUpcomingTripsArgs = {
    limit: Scalars["Int"]["input"];
    offset: Scalars["Int"]["input"];
};

export type QueryDriverAppVersionArgs = {
    currentVersion: Scalars["String"]["input"];
};

export type QueryDriverCountryRuleArgs = {
    destinationCountryId: Scalars["String"]["input"];
    driverCountryId: Scalars["String"]["input"];
    originCountryId: Scalars["String"]["input"];
};

export type QueryDriverCountryRulesArgs = {
    destinationCountryIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
    driverCountryIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    originCountryIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type QueryDriverFaqArgs = {
    ids?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type QueryGetCustomerPhoneNumberForOrderArgs = {
    orderId: Scalars["String"]["input"];
};

export type QueryGetDynamicFormConfigArgs = {
    dynamicFormConfigFindOneInput: DynamicFormConfigFindOneInput;
};

export type QueryGetDynamicFormConfigByIdArgs = {
    id: Scalars["String"]["input"];
};

export type QueryLandMassesForPointArgs = {
    latitude: Scalars["Float"]["input"];
    longitude: Scalars["Float"]["input"];
};

export type QueryPersonalBasicAssignationOfferArgs = {
    id: Scalars["String"]["input"];
};

export type QuerySuitableVehicleModelsArgs = {
    countryIds: Array<Scalars["String"]["input"]>;
    makeIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
    requiredCapacity: Scalars["Float"]["input"];
    vehicleType: VehicleType;
};

export type QuerySuitableVehicleModelsByAssignationOfferArgs = {
    assignationOfferId: Scalars["String"]["input"];
    makeIds: Array<Scalars["String"]["input"]>;
};

export type QuerySuitableVehiclesForCurrentUserArgs = {
    countryIds: Array<Scalars["String"]["input"]>;
    requiredCapacity: Scalars["Float"]["input"];
    vehicleType: VehicleType;
};

export type QuerySuitableVehiclesForCurrentUserByAssignationOfferArgs = {
    assignationOfferId: Scalars["String"]["input"];
};

export type QueryUnavailabilitiesArgs = {
    monthFrom: Scalars["Int"]["input"];
    monthTo: Scalars["Int"]["input"];
    yearFrom: Scalars["Int"]["input"];
    yearTo: Scalars["Int"]["input"];
};

export type QueryVehicleModelsArgs = {
    makeIds: Array<Scalars["String"]["input"]>;
    vehicleTypes: Array<VehicleType>;
};

export type QueryWouldTripRequireWaterTransportArgs = {
    destination: GeoPointInput;
    origin: GeoPointInput;
};

export type RentalVehicle = {
    makeId: Scalars["String"]["output"];
    modelId: Scalars["String"]["output"];
};

export type RentalVehicleInput = {
    makeId: Scalars["String"]["input"];
    modelId: Scalars["String"]["input"];
};

export type RideInput = {
    _id: Scalars["String"]["input"];
    price: Scalars["Float"]["input"];
};

export type RideNote = {
    _id: Scalars["String"]["output"];
    createdAt: Scalars["DateTime"]["output"];
    text: Scalars["String"]["output"];
    userId: Scalars["String"]["output"];
    username: Scalars["String"]["output"];
};

export type RideStop = {
    duration: Scalars["Float"]["output"];
    locationId: Scalars["String"]["output"];
};

export type RuntimeScalarConfigEntry = {
    key: FlexibleOffersRuntimeConfigScalarKeys;
    ttl: Scalars["Float"]["output"];
    value: Scalars["Int"]["output"];
};

export type RuntimeScalarConfigEntryInput = {
    key: FlexibleOffersRuntimeConfigScalarKeys;
    ttl: Scalars["Float"]["input"];
    value: Scalars["Int"]["input"];
};

export type Score = {
    feedbacksAverage?: Maybe<Scalars["Float"]["output"]>;
    totalScore: Scalars["Float"]["output"];
};

export type TokenPair = {
    authentication: Scalars["String"]["output"];
    refresh: Scalars["String"]["output"];
};

export type TransferFromPreviousDestination = {
    distanceKm: Scalars["Float"]["output"];
    durationInMinutes: Scalars["Float"]["output"];
    waitingTimeInMs: Scalars["Float"]["output"];
};

export type Trip = {
    _id: Scalars["String"]["output"];
    assignedUser: User;
    canChangeDriverAndVehicleInApp: Scalars["Boolean"]["output"];
    departureAt: Scalars["DateTime"]["output"];
    destinationCountry: Country;
    destinationLocation: Location;
    isCompanyTrip: Scalars["Boolean"]["output"];
    isPartOfCombo: Scalars["Boolean"]["output"];
    originCountry: Country;
    originLocation: Location;
    passengerGroups: Array<TripPassengerGroup>;
    payments: Array<TripPayment>;
    payout: TripPayout;
    requiredCapacity: Scalars["Int"]["output"];
    stops: Array<TripStop>;
    timezone: Scalars["String"]["output"];
    tripCountryIds: Array<Scalars["String"]["output"]>;
    tripReference: Scalars["String"]["output"];
    type: TripType;
    vehicle: TripVehicle;
};

export type TripPassengerGroup = {
    assignationPassengers: Array<Passenger>;
    customerNote?: Maybe<Scalars["String"]["output"]>;
    driverNote?: Maybe<Scalars["String"]["output"]>;
    dropoffAddress: Scalars["String"]["output"];
    leadPassenger: Passenger;
    orderId: Scalars["String"]["output"];
    passengers: Array<Passenger>;
    pickupAddress: Scalars["String"]["output"];
    reference: Scalars["String"]["output"];
};

export type TripPayment = {
    cardAlias?: Maybe<Scalars["String"]["output"]>;
    cardholderName?: Maybe<Scalars["String"]["output"]>;
    cashAmount?: Maybe<Scalars["Float"]["output"]>;
    currency: Currency;
    orderId: Scalars["String"]["output"];
    potentialFraud: Scalars["Boolean"]["output"];
    userId: Scalars["String"]["output"];
};

export type TripPayout = {
    amount: Scalars["Float"]["output"];
    currency: Currency;
};

export type TripStop = {
    duration: Scalars["Float"]["output"];
    name: Scalars["String"]["output"];
    order: Scalars["Float"]["output"];
};

export enum TripType {
    Pool = "Pool",
    Private = "Private",
}

export type TripVehicle = {
    licensePlate?: Maybe<Scalars["String"]["output"]>;
    make: VehicleMake;
    model: VehicleModel;
    type: VehicleType;
    vehicleId?: Maybe<Scalars["String"]["output"]>;
    vehicleNote?: Maybe<Scalars["String"]["output"]>;
};

export type Unavailability = {
    _id: Scalars["String"]["output"];
    createdAt: Scalars["DateTime"]["output"];
    date: Scalars["DateTime"]["output"];
    userId: Scalars["String"]["output"];
};

export type UpdateDynamicFormConfigInput = {
    dynamicFormConfigId: Scalars["String"]["input"];
    entityTypeLegalName: Scalars["String"]["input"];
    fields: Array<DynamicFormConfigFieldInput>;
};

export type UsageHistoryArgs = {
    orderId: Scalars["String"]["input"];
    usedAt: Scalars["DateTime"]["input"];
    userId: Scalars["String"]["input"];
};

export type User = {
    _id: Scalars["String"]["output"];
    billingInformationValidationStatus: BillingInformationValidationStatus;
    cooperationAgreementStatus: CooperationAgreementStatus;
    driver?: Maybe<Driver>;
    driversCompany?: Maybe<DriversCompany>;
    featureFlags: Array<FeatureFlagKeyValue>;
    firstName: Scalars["String"]["output"];
    homeAddress?: Maybe<Scalars["String"]["output"]>;
    homePosition?: Maybe<GeoPoint>;
    isDriverUnderInsurance: Scalars["Boolean"]["output"];
    isDriversCompany: Scalars["Boolean"]["output"];
    lastName: Scalars["String"]["output"];
    profilePhotoUrl?: Maybe<Scalars["String"]["output"]>;
    publicOffersPreferences?: Maybe<PublicOffersPreferences>;
};

export type UserProfile = {
    tokens: TokenPair;
    user: User;
};

export type Vehicle = {
    _id: Scalars["String"]["output"];
    color: Scalars["Int"]["output"];
    licensePlate: Scalars["String"]["output"];
    make: VehicleMake;
    manufactureYear: Scalars["Float"]["output"];
    model: VehicleModel;
    modelId: Scalars["String"]["output"];
    ownerUserId: Scalars["String"]["output"];
};

export type VehicleMake = {
    _id: Scalars["String"]["output"];
    name: Scalars["String"]["output"];
};

export type VehicleModel = {
    _id: Scalars["String"]["output"];
    makeId: Scalars["String"]["output"];
    name: Scalars["String"]["output"];
    seatsCount: Scalars["Float"]["output"];
};

export enum VehicleType {
    LuxurySedan = "LuxurySedan",
    Sedan = "Sedan",
    SedanLite = "SedanLite",
    Mpv = "Mpv",
    MpvLite = "MpvLite",
    Van = "Van",
    VanLite = "VanLite",
    Shuttle = "Shuttle",
}

export type GetAllCountriesForSelectQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllCountriesForSelectQueryOperationResult = {
    countriesByEnglishName: Array<{ _id: string; isoCode: string; englishName: string }>;
};

export type GetDynamicFormConfigQueryVariables = Exact<{
    input: DynamicFormConfigFindOneInput;
}>;

export type GetDynamicFormConfigQueryOperationResult = {
    getDynamicFormConfig: {
        _id: string;
        countryId?: string | null;
        entityType: DynamicFormConfigEntityType;
        entityTypeLegalName: string;
        formType: DynamicFormConfigFormType;
        fields: Array<{
            englishName: string;
            fieldName: string;
            isRequired: boolean;
            localName: string;
            order: number;
            placeholder: string;
        }>;
    };
};

export type UpsertDynamicFormConfigMutationVariables = Exact<{
    input: CreateDynamicFormConfigInput;
}>;

export type UpsertDynamicFormConfigMutationOperationResult = {
    upsertDynamicFormConfig: {
        _id: string;
        countryId?: string | null;
        entityType: DynamicFormConfigEntityType;
        entityTypeLegalName: string;
        formType: DynamicFormConfigFormType;
        fields: Array<{
            englishName: string;
            fieldName: string;
            isRequired: boolean;
            localName: string;
            order: number;
            placeholder: string;
        }>;
    };
};

export type DriverCountryRuleForEditFragment = {
    driverCountryId: string;
    originCountryId: string;
    destinationCountryId: string;
    canAcceptAssignationOffers: boolean;
    canBeAutomaticallyAssigned: boolean;
};

export type DriverCountryRuleForTableFragment = {
    driverCountryId: string;
    originCountryId: string;
    destinationCountryId: string;
    canAcceptAssignationOffers: boolean;
    canBeAutomaticallyAssigned: boolean;
    driverCountry: { isoCode: string; englishName: string };
    originCountry: { isoCode: string; englishName: string };
    destinationCountry: { isoCode: string; englishName: string };
};

export type CreateDriverCountryRuleMutationVariables = Exact<{
    input: DriverCountryRuleInput;
}>;

export type CreateDriverCountryRuleMutationOperationResult = { createDriverCountryRule: boolean };

export type DeleteDriverCountryRuleMutationVariables = Exact<{
    driverCountryId: Scalars["String"]["input"];
    originCountryId: Scalars["String"]["input"];
    destinationCountryId: Scalars["String"]["input"];
}>;

export type DeleteDriverCountryRuleMutationOperationResult = { deleteDriverCountryRule: boolean };

export type UpdateDriverCountryRuleMutationVariables = Exact<{
    driverCountryId: Scalars["String"]["input"];
    originCountryId: Scalars["String"]["input"];
    destinationCountryId: Scalars["String"]["input"];
    update: DriverCountryRuleUpdateInput;
}>;

export type UpdateDriverCountryRuleMutationOperationResult = { updateDriverCountryRule: boolean };

export type GetDriverCountryRuleForEditQueryVariables = Exact<{
    driverCountryId: Scalars["String"]["input"];
    originCountryId: Scalars["String"]["input"];
    destinationCountryId: Scalars["String"]["input"];
}>;

export type GetDriverCountryRuleForEditQueryOperationResult = {
    driverCountryRule: {
        driverCountryId: string;
        originCountryId: string;
        destinationCountryId: string;
        canAcceptAssignationOffers: boolean;
        canBeAutomaticallyAssigned: boolean;
    };
};

export type ListDriverCountryRulesForTableQueryVariables = Exact<{
    driverCountryIds?: InputMaybe<Array<Scalars["String"]["input"]> | Scalars["String"]["input"]>;
    originCountryIds?: InputMaybe<Array<Scalars["String"]["input"]> | Scalars["String"]["input"]>;
    destinationCountryIds?: InputMaybe<Array<Scalars["String"]["input"]> | Scalars["String"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type ListDriverCountryRulesForTableQueryOperationResult = {
    driverCountryRules: {
        totalCount: number;
        data: Array<{
            driverCountryId: string;
            originCountryId: string;
            destinationCountryId: string;
            canAcceptAssignationOffers: boolean;
            canBeAutomaticallyAssigned: boolean;
            driverCountry: { isoCode: string; englishName: string };
            originCountry: { isoCode: string; englishName: string };
            destinationCountry: { isoCode: string; englishName: string };
        }>;
    };
};

export type AddCrossBorderTripWhitelistEntryMutationVariables = Exact<{
    entry: CrossBorderTripWhitelistEntryInput;
}>;

export type AddCrossBorderTripWhitelistEntryMutationOperationResult = { addCrossBorderTripWhitelistEntry: boolean };

export type CountriesByEnglishNameQueryVariables = Exact<{ [key: string]: never }>;

export type CountriesByEnglishNameQueryOperationResult = { countriesByEnglishName: Array<{ isoCode: string }> };

export type CrossBorderWhitelistQueryVariables = Exact<{ [key: string]: never }>;

export type CrossBorderWhitelistQueryOperationResult = {
    flexibleOffersRuntimeConfig: {
        crossBorderTripWhitelist: Array<{
            countryIsoA: string;
            countryIsoB: string;
            additionalFee?: number | null;
            additionalPrice?: number | null;
            preferredPricing?: string | null;
        }>;
    };
};

export type RemoveCrossBorderTripWhitelistEntryMutationVariables = Exact<{
    entry: CrossBorderTripWhitelistEntryInput;
}>;

export type RemoveCrossBorderTripWhitelistEntryMutationOperationResult = {
    removeCrossBorderTripWhitelistEntry: boolean;
};

export type UpdateCrossBorderTripWhitelistEntryMutationVariables = Exact<{
    entry: CrossBorderTripWhitelistEntryInput;
}>;

export type UpdateCrossBorderTripWhitelistEntryMutationOperationResult = {
    updateCrossBorderTripWhitelistEntry: boolean;
};

export type FlexibleOffersGlobalScalarConfigQueryVariables = Exact<{ [key: string]: never }>;

export type FlexibleOffersGlobalScalarConfigQueryOperationResult = {
    flexibleOffersGlobalScalarConfig: Array<{ key: FlexibleOffersRuntimeConfigScalarKeys; value: number }>;
};

export type UpdateFoScalarConfigParameterMutationVariables = Exact<{
    key: FlexibleOffersRuntimeConfigScalarKeys;
    value: Scalars["Int"]["input"];
}>;

export type UpdateFoScalarConfigParameterMutationOperationResult = { configEntryPutScalarParameters: boolean };

export type UpdateRideMutationVariables = Exact<{
    ride: RideInput;
}>;

export type UpdateRideMutationOperationResult = { updateRide: string };

export interface PossibleTypesResultData {
    possibleTypes: {
        [key: string]: string[];
    };
}
const result: PossibleTypesResultData = {
    possibleTypes: {
        FlexibleOffersRuntimeConfigValues: [
            "FlexibleOffersRuntimeConfigValueEnabledRegions",
            "FlexibleOffersRuntimeConfigValueNumber",
        ],
    },
};
export default result;

export const DriverCountryRuleForEditFragmentDoc = gql`
    fragment DriverCountryRuleForEdit on DriverCountryRule {
        driverCountryId
        originCountryId
        destinationCountryId
        canAcceptAssignationOffers
        canBeAutomaticallyAssigned
    }
`;
export const DriverCountryRuleForTableFragmentDoc = gql`
    fragment DriverCountryRuleForTable on DriverCountryRule {
        driverCountryId
        driverCountry {
            isoCode
            englishName
        }
        originCountryId
        originCountry {
            isoCode
            englishName
        }
        destinationCountryId
        destinationCountry {
            isoCode
            englishName
        }
        canAcceptAssignationOffers
        canBeAutomaticallyAssigned
    }
`;
export const GetAllCountriesForSelectDocument = gql`
    query GetAllCountriesForSelect {
        countriesByEnglishName {
            _id
            isoCode
            englishName
        }
    }
`;

/**
 * __useGetAllCountriesForSelectQuery__
 *
 * To run a query within a React component, call `useGetAllCountriesForSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCountriesForSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCountriesForSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCountriesForSelectQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetAllCountriesForSelectQueryOperationResult,
        GetAllCountriesForSelectQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetAllCountriesForSelectQueryOperationResult, GetAllCountriesForSelectQueryVariables>(
        GetAllCountriesForSelectDocument,
        options,
    );
}
export function useGetAllCountriesForSelectLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAllCountriesForSelectQueryOperationResult,
        GetAllCountriesForSelectQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetAllCountriesForSelectQueryOperationResult, GetAllCountriesForSelectQueryVariables>(
        GetAllCountriesForSelectDocument,
        options,
    );
}
export function useGetAllCountriesForSelectSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        GetAllCountriesForSelectQueryOperationResult,
        GetAllCountriesForSelectQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetAllCountriesForSelectQueryOperationResult,
        GetAllCountriesForSelectQueryVariables
    >(GetAllCountriesForSelectDocument, options);
}
export type GetAllCountriesForSelectQueryHookResult = ReturnType<typeof useGetAllCountriesForSelectQuery>;
export type GetAllCountriesForSelectLazyQueryHookResult = ReturnType<typeof useGetAllCountriesForSelectLazyQuery>;
export type GetAllCountriesForSelectSuspenseQueryHookResult = ReturnType<
    typeof useGetAllCountriesForSelectSuspenseQuery
>;
export type GetAllCountriesForSelectQueryResult = Apollo.QueryResult<
    GetAllCountriesForSelectQueryOperationResult,
    GetAllCountriesForSelectQueryVariables
>;
export const GetDynamicFormConfigDocument = gql`
    query GetDynamicFormConfig($input: DynamicFormConfigFindOneInput!) {
        getDynamicFormConfig(dynamicFormConfigFindOneInput: $input) {
            _id
            countryId
            entityType
            entityTypeLegalName
            fields {
                englishName
                fieldName
                isRequired
                localName
                order
                placeholder
            }
            formType
        }
    }
`;

/**
 * __useGetDynamicFormConfigQuery__
 *
 * To run a query within a React component, call `useGetDynamicFormConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDynamicFormConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDynamicFormConfigQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDynamicFormConfigQuery(
    baseOptions: Apollo.QueryHookOptions<GetDynamicFormConfigQueryOperationResult, GetDynamicFormConfigQueryVariables> &
        ({ variables: GetDynamicFormConfigQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetDynamicFormConfigQueryOperationResult, GetDynamicFormConfigQueryVariables>(
        GetDynamicFormConfigDocument,
        options,
    );
}
export function useGetDynamicFormConfigLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetDynamicFormConfigQueryOperationResult,
        GetDynamicFormConfigQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetDynamicFormConfigQueryOperationResult, GetDynamicFormConfigQueryVariables>(
        GetDynamicFormConfigDocument,
        options,
    );
}
export function useGetDynamicFormConfigSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        GetDynamicFormConfigQueryOperationResult,
        GetDynamicFormConfigQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetDynamicFormConfigQueryOperationResult, GetDynamicFormConfigQueryVariables>(
        GetDynamicFormConfigDocument,
        options,
    );
}
export type GetDynamicFormConfigQueryHookResult = ReturnType<typeof useGetDynamicFormConfigQuery>;
export type GetDynamicFormConfigLazyQueryHookResult = ReturnType<typeof useGetDynamicFormConfigLazyQuery>;
export type GetDynamicFormConfigSuspenseQueryHookResult = ReturnType<typeof useGetDynamicFormConfigSuspenseQuery>;
export type GetDynamicFormConfigQueryResult = Apollo.QueryResult<
    GetDynamicFormConfigQueryOperationResult,
    GetDynamicFormConfigQueryVariables
>;
export const UpsertDynamicFormConfigDocument = gql`
    mutation UpsertDynamicFormConfig($input: CreateDynamicFormConfigInput!) {
        upsertDynamicFormConfig(upsertDynamicFormConfigInput: $input) {
            _id
            countryId
            entityType
            entityTypeLegalName
            formType
            fields {
                englishName
                fieldName
                isRequired
                localName
                order
                placeholder
            }
        }
    }
`;
export type UpsertDynamicFormConfigMutationFn = Apollo.MutationFunction<
    UpsertDynamicFormConfigMutationOperationResult,
    UpsertDynamicFormConfigMutationVariables
>;

/**
 * __useUpsertDynamicFormConfigMutation__
 *
 * To run a mutation, you first call `useUpsertDynamicFormConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertDynamicFormConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertDynamicFormConfigMutation, { data, loading, error }] = useUpsertDynamicFormConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertDynamicFormConfigMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpsertDynamicFormConfigMutationOperationResult,
        UpsertDynamicFormConfigMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpsertDynamicFormConfigMutationOperationResult, UpsertDynamicFormConfigMutationVariables>(
        UpsertDynamicFormConfigDocument,
        options,
    );
}
export type UpsertDynamicFormConfigMutationHookResult = ReturnType<typeof useUpsertDynamicFormConfigMutation>;
export type UpsertDynamicFormConfigMutationResult =
    Apollo.MutationResult<UpsertDynamicFormConfigMutationOperationResult>;
export type UpsertDynamicFormConfigMutationOptions = Apollo.BaseMutationOptions<
    UpsertDynamicFormConfigMutationOperationResult,
    UpsertDynamicFormConfigMutationVariables
>;
export const CreateDriverCountryRuleDocument = gql`
    mutation CreateDriverCountryRule($input: DriverCountryRuleInput!) {
        createDriverCountryRule(input: $input)
    }
`;
export type CreateDriverCountryRuleMutationFn = Apollo.MutationFunction<
    CreateDriverCountryRuleMutationOperationResult,
    CreateDriverCountryRuleMutationVariables
>;

/**
 * __useCreateDriverCountryRuleMutation__
 *
 * To run a mutation, you first call `useCreateDriverCountryRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDriverCountryRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDriverCountryRuleMutation, { data, loading, error }] = useCreateDriverCountryRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDriverCountryRuleMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateDriverCountryRuleMutationOperationResult,
        CreateDriverCountryRuleMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateDriverCountryRuleMutationOperationResult, CreateDriverCountryRuleMutationVariables>(
        CreateDriverCountryRuleDocument,
        options,
    );
}
export type CreateDriverCountryRuleMutationHookResult = ReturnType<typeof useCreateDriverCountryRuleMutation>;
export type CreateDriverCountryRuleMutationResult =
    Apollo.MutationResult<CreateDriverCountryRuleMutationOperationResult>;
export type CreateDriverCountryRuleMutationOptions = Apollo.BaseMutationOptions<
    CreateDriverCountryRuleMutationOperationResult,
    CreateDriverCountryRuleMutationVariables
>;
export const DeleteDriverCountryRuleDocument = gql`
    mutation DeleteDriverCountryRule(
        $driverCountryId: String!
        $originCountryId: String!
        $destinationCountryId: String!
    ) {
        deleteDriverCountryRule(
            driverCountryId: $driverCountryId
            originCountryId: $originCountryId
            destinationCountryId: $destinationCountryId
        )
    }
`;
export type DeleteDriverCountryRuleMutationFn = Apollo.MutationFunction<
    DeleteDriverCountryRuleMutationOperationResult,
    DeleteDriverCountryRuleMutationVariables
>;

/**
 * __useDeleteDriverCountryRuleMutation__
 *
 * To run a mutation, you first call `useDeleteDriverCountryRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDriverCountryRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDriverCountryRuleMutation, { data, loading, error }] = useDeleteDriverCountryRuleMutation({
 *   variables: {
 *      driverCountryId: // value for 'driverCountryId'
 *      originCountryId: // value for 'originCountryId'
 *      destinationCountryId: // value for 'destinationCountryId'
 *   },
 * });
 */
export function useDeleteDriverCountryRuleMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteDriverCountryRuleMutationOperationResult,
        DeleteDriverCountryRuleMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteDriverCountryRuleMutationOperationResult, DeleteDriverCountryRuleMutationVariables>(
        DeleteDriverCountryRuleDocument,
        options,
    );
}
export type DeleteDriverCountryRuleMutationHookResult = ReturnType<typeof useDeleteDriverCountryRuleMutation>;
export type DeleteDriverCountryRuleMutationResult =
    Apollo.MutationResult<DeleteDriverCountryRuleMutationOperationResult>;
export type DeleteDriverCountryRuleMutationOptions = Apollo.BaseMutationOptions<
    DeleteDriverCountryRuleMutationOperationResult,
    DeleteDriverCountryRuleMutationVariables
>;
export const UpdateDriverCountryRuleDocument = gql`
    mutation UpdateDriverCountryRule(
        $driverCountryId: String!
        $originCountryId: String!
        $destinationCountryId: String!
        $update: DriverCountryRuleUpdateInput!
    ) {
        updateDriverCountryRule(
            driverCountryId: $driverCountryId
            originCountryId: $originCountryId
            destinationCountryId: $destinationCountryId
            update: $update
        )
    }
`;
export type UpdateDriverCountryRuleMutationFn = Apollo.MutationFunction<
    UpdateDriverCountryRuleMutationOperationResult,
    UpdateDriverCountryRuleMutationVariables
>;

/**
 * __useUpdateDriverCountryRuleMutation__
 *
 * To run a mutation, you first call `useUpdateDriverCountryRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverCountryRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverCountryRuleMutation, { data, loading, error }] = useUpdateDriverCountryRuleMutation({
 *   variables: {
 *      driverCountryId: // value for 'driverCountryId'
 *      originCountryId: // value for 'originCountryId'
 *      destinationCountryId: // value for 'destinationCountryId'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateDriverCountryRuleMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateDriverCountryRuleMutationOperationResult,
        UpdateDriverCountryRuleMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateDriverCountryRuleMutationOperationResult, UpdateDriverCountryRuleMutationVariables>(
        UpdateDriverCountryRuleDocument,
        options,
    );
}
export type UpdateDriverCountryRuleMutationHookResult = ReturnType<typeof useUpdateDriverCountryRuleMutation>;
export type UpdateDriverCountryRuleMutationResult =
    Apollo.MutationResult<UpdateDriverCountryRuleMutationOperationResult>;
export type UpdateDriverCountryRuleMutationOptions = Apollo.BaseMutationOptions<
    UpdateDriverCountryRuleMutationOperationResult,
    UpdateDriverCountryRuleMutationVariables
>;
export const GetDriverCountryRuleForEditDocument = gql`
    query GetDriverCountryRuleForEdit(
        $driverCountryId: String!
        $originCountryId: String!
        $destinationCountryId: String!
    ) {
        driverCountryRule(
            driverCountryId: $driverCountryId
            originCountryId: $originCountryId
            destinationCountryId: $destinationCountryId
        ) {
            ...DriverCountryRuleForEdit
        }
    }
    ${DriverCountryRuleForEditFragmentDoc}
`;

/**
 * __useGetDriverCountryRuleForEditQuery__
 *
 * To run a query within a React component, call `useGetDriverCountryRuleForEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverCountryRuleForEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverCountryRuleForEditQuery({
 *   variables: {
 *      driverCountryId: // value for 'driverCountryId'
 *      originCountryId: // value for 'originCountryId'
 *      destinationCountryId: // value for 'destinationCountryId'
 *   },
 * });
 */
export function useGetDriverCountryRuleForEditQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetDriverCountryRuleForEditQueryOperationResult,
        GetDriverCountryRuleForEditQueryVariables
    > &
        ({ variables: GetDriverCountryRuleForEditQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetDriverCountryRuleForEditQueryOperationResult, GetDriverCountryRuleForEditQueryVariables>(
        GetDriverCountryRuleForEditDocument,
        options,
    );
}
export function useGetDriverCountryRuleForEditLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetDriverCountryRuleForEditQueryOperationResult,
        GetDriverCountryRuleForEditQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetDriverCountryRuleForEditQueryOperationResult,
        GetDriverCountryRuleForEditQueryVariables
    >(GetDriverCountryRuleForEditDocument, options);
}
export function useGetDriverCountryRuleForEditSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        GetDriverCountryRuleForEditQueryOperationResult,
        GetDriverCountryRuleForEditQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetDriverCountryRuleForEditQueryOperationResult,
        GetDriverCountryRuleForEditQueryVariables
    >(GetDriverCountryRuleForEditDocument, options);
}
export type GetDriverCountryRuleForEditQueryHookResult = ReturnType<typeof useGetDriverCountryRuleForEditQuery>;
export type GetDriverCountryRuleForEditLazyQueryHookResult = ReturnType<typeof useGetDriverCountryRuleForEditLazyQuery>;
export type GetDriverCountryRuleForEditSuspenseQueryHookResult = ReturnType<
    typeof useGetDriverCountryRuleForEditSuspenseQuery
>;
export type GetDriverCountryRuleForEditQueryResult = Apollo.QueryResult<
    GetDriverCountryRuleForEditQueryOperationResult,
    GetDriverCountryRuleForEditQueryVariables
>;
export const ListDriverCountryRulesForTableDocument = gql`
    query ListDriverCountryRulesForTable(
        $driverCountryIds: [String!]
        $originCountryIds: [String!]
        $destinationCountryIds: [String!]
        $offset: Int
        $limit: Int
    ) {
        driverCountryRules(
            driverCountryIds: $driverCountryIds
            originCountryIds: $originCountryIds
            destinationCountryIds: $destinationCountryIds
            offset: $offset
            limit: $limit
        ) {
            data {
                ...DriverCountryRuleForTable
            }
            totalCount
        }
    }
    ${DriverCountryRuleForTableFragmentDoc}
`;

/**
 * __useListDriverCountryRulesForTableQuery__
 *
 * To run a query within a React component, call `useListDriverCountryRulesForTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDriverCountryRulesForTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDriverCountryRulesForTableQuery({
 *   variables: {
 *      driverCountryIds: // value for 'driverCountryIds'
 *      originCountryIds: // value for 'originCountryIds'
 *      destinationCountryIds: // value for 'destinationCountryIds'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useListDriverCountryRulesForTableQuery(
    baseOptions?: Apollo.QueryHookOptions<
        ListDriverCountryRulesForTableQueryOperationResult,
        ListDriverCountryRulesForTableQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        ListDriverCountryRulesForTableQueryOperationResult,
        ListDriverCountryRulesForTableQueryVariables
    >(ListDriverCountryRulesForTableDocument, options);
}
export function useListDriverCountryRulesForTableLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ListDriverCountryRulesForTableQueryOperationResult,
        ListDriverCountryRulesForTableQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        ListDriverCountryRulesForTableQueryOperationResult,
        ListDriverCountryRulesForTableQueryVariables
    >(ListDriverCountryRulesForTableDocument, options);
}
export function useListDriverCountryRulesForTableSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        ListDriverCountryRulesForTableQueryOperationResult,
        ListDriverCountryRulesForTableQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        ListDriverCountryRulesForTableQueryOperationResult,
        ListDriverCountryRulesForTableQueryVariables
    >(ListDriverCountryRulesForTableDocument, options);
}
export type ListDriverCountryRulesForTableQueryHookResult = ReturnType<typeof useListDriverCountryRulesForTableQuery>;
export type ListDriverCountryRulesForTableLazyQueryHookResult = ReturnType<
    typeof useListDriverCountryRulesForTableLazyQuery
>;
export type ListDriverCountryRulesForTableSuspenseQueryHookResult = ReturnType<
    typeof useListDriverCountryRulesForTableSuspenseQuery
>;
export type ListDriverCountryRulesForTableQueryResult = Apollo.QueryResult<
    ListDriverCountryRulesForTableQueryOperationResult,
    ListDriverCountryRulesForTableQueryVariables
>;
export const AddCrossBorderTripWhitelistEntryDocument = gql`
    mutation addCrossBorderTripWhitelistEntry($entry: CrossBorderTripWhitelistEntryInput!) {
        addCrossBorderTripWhitelistEntry(crossBorderTripWhitelistEntry: $entry)
    }
`;
export type AddCrossBorderTripWhitelistEntryMutationFn = Apollo.MutationFunction<
    AddCrossBorderTripWhitelistEntryMutationOperationResult,
    AddCrossBorderTripWhitelistEntryMutationVariables
>;

/**
 * __useAddCrossBorderTripWhitelistEntryMutation__
 *
 * To run a mutation, you first call `useAddCrossBorderTripWhitelistEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCrossBorderTripWhitelistEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCrossBorderTripWhitelistEntryMutation, { data, loading, error }] = useAddCrossBorderTripWhitelistEntryMutation({
 *   variables: {
 *      entry: // value for 'entry'
 *   },
 * });
 */
export function useAddCrossBorderTripWhitelistEntryMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AddCrossBorderTripWhitelistEntryMutationOperationResult,
        AddCrossBorderTripWhitelistEntryMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        AddCrossBorderTripWhitelistEntryMutationOperationResult,
        AddCrossBorderTripWhitelistEntryMutationVariables
    >(AddCrossBorderTripWhitelistEntryDocument, options);
}
export type AddCrossBorderTripWhitelistEntryMutationHookResult = ReturnType<
    typeof useAddCrossBorderTripWhitelistEntryMutation
>;
export type AddCrossBorderTripWhitelistEntryMutationResult =
    Apollo.MutationResult<AddCrossBorderTripWhitelistEntryMutationOperationResult>;
export type AddCrossBorderTripWhitelistEntryMutationOptions = Apollo.BaseMutationOptions<
    AddCrossBorderTripWhitelistEntryMutationOperationResult,
    AddCrossBorderTripWhitelistEntryMutationVariables
>;
export const CountriesByEnglishNameDocument = gql`
    query countriesByEnglishName {
        countriesByEnglishName {
            isoCode
        }
    }
`;

/**
 * __useCountriesByEnglishNameQuery__
 *
 * To run a query within a React component, call `useCountriesByEnglishNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesByEnglishNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesByEnglishNameQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesByEnglishNameQuery(
    baseOptions?: Apollo.QueryHookOptions<
        CountriesByEnglishNameQueryOperationResult,
        CountriesByEnglishNameQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CountriesByEnglishNameQueryOperationResult, CountriesByEnglishNameQueryVariables>(
        CountriesByEnglishNameDocument,
        options,
    );
}
export function useCountriesByEnglishNameLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CountriesByEnglishNameQueryOperationResult,
        CountriesByEnglishNameQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CountriesByEnglishNameQueryOperationResult, CountriesByEnglishNameQueryVariables>(
        CountriesByEnglishNameDocument,
        options,
    );
}
export function useCountriesByEnglishNameSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        CountriesByEnglishNameQueryOperationResult,
        CountriesByEnglishNameQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<CountriesByEnglishNameQueryOperationResult, CountriesByEnglishNameQueryVariables>(
        CountriesByEnglishNameDocument,
        options,
    );
}
export type CountriesByEnglishNameQueryHookResult = ReturnType<typeof useCountriesByEnglishNameQuery>;
export type CountriesByEnglishNameLazyQueryHookResult = ReturnType<typeof useCountriesByEnglishNameLazyQuery>;
export type CountriesByEnglishNameSuspenseQueryHookResult = ReturnType<typeof useCountriesByEnglishNameSuspenseQuery>;
export type CountriesByEnglishNameQueryResult = Apollo.QueryResult<
    CountriesByEnglishNameQueryOperationResult,
    CountriesByEnglishNameQueryVariables
>;
export const CrossBorderWhitelistDocument = gql`
    query crossBorderWhitelist {
        flexibleOffersRuntimeConfig {
            crossBorderTripWhitelist {
                countryIsoA
                countryIsoB
                additionalFee
                additionalPrice
                preferredPricing
            }
        }
    }
`;

/**
 * __useCrossBorderWhitelistQuery__
 *
 * To run a query within a React component, call `useCrossBorderWhitelistQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrossBorderWhitelistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrossBorderWhitelistQuery({
 *   variables: {
 *   },
 * });
 */
export function useCrossBorderWhitelistQuery(
    baseOptions?: Apollo.QueryHookOptions<CrossBorderWhitelistQueryOperationResult, CrossBorderWhitelistQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CrossBorderWhitelistQueryOperationResult, CrossBorderWhitelistQueryVariables>(
        CrossBorderWhitelistDocument,
        options,
    );
}
export function useCrossBorderWhitelistLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CrossBorderWhitelistQueryOperationResult,
        CrossBorderWhitelistQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CrossBorderWhitelistQueryOperationResult, CrossBorderWhitelistQueryVariables>(
        CrossBorderWhitelistDocument,
        options,
    );
}
export function useCrossBorderWhitelistSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        CrossBorderWhitelistQueryOperationResult,
        CrossBorderWhitelistQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<CrossBorderWhitelistQueryOperationResult, CrossBorderWhitelistQueryVariables>(
        CrossBorderWhitelistDocument,
        options,
    );
}
export type CrossBorderWhitelistQueryHookResult = ReturnType<typeof useCrossBorderWhitelistQuery>;
export type CrossBorderWhitelistLazyQueryHookResult = ReturnType<typeof useCrossBorderWhitelistLazyQuery>;
export type CrossBorderWhitelistSuspenseQueryHookResult = ReturnType<typeof useCrossBorderWhitelistSuspenseQuery>;
export type CrossBorderWhitelistQueryResult = Apollo.QueryResult<
    CrossBorderWhitelistQueryOperationResult,
    CrossBorderWhitelistQueryVariables
>;
export const RemoveCrossBorderTripWhitelistEntryDocument = gql`
    mutation removeCrossBorderTripWhitelistEntry($entry: CrossBorderTripWhitelistEntryInput!) {
        removeCrossBorderTripWhitelistEntry(crossBorderTripWhitelistEntry: $entry)
    }
`;
export type RemoveCrossBorderTripWhitelistEntryMutationFn = Apollo.MutationFunction<
    RemoveCrossBorderTripWhitelistEntryMutationOperationResult,
    RemoveCrossBorderTripWhitelistEntryMutationVariables
>;

/**
 * __useRemoveCrossBorderTripWhitelistEntryMutation__
 *
 * To run a mutation, you first call `useRemoveCrossBorderTripWhitelistEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCrossBorderTripWhitelistEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCrossBorderTripWhitelistEntryMutation, { data, loading, error }] = useRemoveCrossBorderTripWhitelistEntryMutation({
 *   variables: {
 *      entry: // value for 'entry'
 *   },
 * });
 */
export function useRemoveCrossBorderTripWhitelistEntryMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RemoveCrossBorderTripWhitelistEntryMutationOperationResult,
        RemoveCrossBorderTripWhitelistEntryMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        RemoveCrossBorderTripWhitelistEntryMutationOperationResult,
        RemoveCrossBorderTripWhitelistEntryMutationVariables
    >(RemoveCrossBorderTripWhitelistEntryDocument, options);
}
export type RemoveCrossBorderTripWhitelistEntryMutationHookResult = ReturnType<
    typeof useRemoveCrossBorderTripWhitelistEntryMutation
>;
export type RemoveCrossBorderTripWhitelistEntryMutationResult =
    Apollo.MutationResult<RemoveCrossBorderTripWhitelistEntryMutationOperationResult>;
export type RemoveCrossBorderTripWhitelistEntryMutationOptions = Apollo.BaseMutationOptions<
    RemoveCrossBorderTripWhitelistEntryMutationOperationResult,
    RemoveCrossBorderTripWhitelistEntryMutationVariables
>;
export const UpdateCrossBorderTripWhitelistEntryDocument = gql`
    mutation updateCrossBorderTripWhitelistEntry($entry: CrossBorderTripWhitelistEntryInput!) {
        updateCrossBorderTripWhitelistEntry(crossBorderTripWhitelistEntry: $entry)
    }
`;
export type UpdateCrossBorderTripWhitelistEntryMutationFn = Apollo.MutationFunction<
    UpdateCrossBorderTripWhitelistEntryMutationOperationResult,
    UpdateCrossBorderTripWhitelistEntryMutationVariables
>;

/**
 * __useUpdateCrossBorderTripWhitelistEntryMutation__
 *
 * To run a mutation, you first call `useUpdateCrossBorderTripWhitelistEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCrossBorderTripWhitelistEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCrossBorderTripWhitelistEntryMutation, { data, loading, error }] = useUpdateCrossBorderTripWhitelistEntryMutation({
 *   variables: {
 *      entry: // value for 'entry'
 *   },
 * });
 */
export function useUpdateCrossBorderTripWhitelistEntryMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateCrossBorderTripWhitelistEntryMutationOperationResult,
        UpdateCrossBorderTripWhitelistEntryMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateCrossBorderTripWhitelistEntryMutationOperationResult,
        UpdateCrossBorderTripWhitelistEntryMutationVariables
    >(UpdateCrossBorderTripWhitelistEntryDocument, options);
}
export type UpdateCrossBorderTripWhitelistEntryMutationHookResult = ReturnType<
    typeof useUpdateCrossBorderTripWhitelistEntryMutation
>;
export type UpdateCrossBorderTripWhitelistEntryMutationResult =
    Apollo.MutationResult<UpdateCrossBorderTripWhitelistEntryMutationOperationResult>;
export type UpdateCrossBorderTripWhitelistEntryMutationOptions = Apollo.BaseMutationOptions<
    UpdateCrossBorderTripWhitelistEntryMutationOperationResult,
    UpdateCrossBorderTripWhitelistEntryMutationVariables
>;
export const FlexibleOffersGlobalScalarConfigDocument = gql`
    query flexibleOffersGlobalScalarConfig {
        flexibleOffersGlobalScalarConfig {
            key
            value
        }
    }
`;

/**
 * __useFlexibleOffersGlobalScalarConfigQuery__
 *
 * To run a query within a React component, call `useFlexibleOffersGlobalScalarConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlexibleOffersGlobalScalarConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlexibleOffersGlobalScalarConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useFlexibleOffersGlobalScalarConfigQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FlexibleOffersGlobalScalarConfigQueryOperationResult,
        FlexibleOffersGlobalScalarConfigQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        FlexibleOffersGlobalScalarConfigQueryOperationResult,
        FlexibleOffersGlobalScalarConfigQueryVariables
    >(FlexibleOffersGlobalScalarConfigDocument, options);
}
export function useFlexibleOffersGlobalScalarConfigLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FlexibleOffersGlobalScalarConfigQueryOperationResult,
        FlexibleOffersGlobalScalarConfigQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FlexibleOffersGlobalScalarConfigQueryOperationResult,
        FlexibleOffersGlobalScalarConfigQueryVariables
    >(FlexibleOffersGlobalScalarConfigDocument, options);
}
export function useFlexibleOffersGlobalScalarConfigSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        FlexibleOffersGlobalScalarConfigQueryOperationResult,
        FlexibleOffersGlobalScalarConfigQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        FlexibleOffersGlobalScalarConfigQueryOperationResult,
        FlexibleOffersGlobalScalarConfigQueryVariables
    >(FlexibleOffersGlobalScalarConfigDocument, options);
}
export type FlexibleOffersGlobalScalarConfigQueryHookResult = ReturnType<
    typeof useFlexibleOffersGlobalScalarConfigQuery
>;
export type FlexibleOffersGlobalScalarConfigLazyQueryHookResult = ReturnType<
    typeof useFlexibleOffersGlobalScalarConfigLazyQuery
>;
export type FlexibleOffersGlobalScalarConfigSuspenseQueryHookResult = ReturnType<
    typeof useFlexibleOffersGlobalScalarConfigSuspenseQuery
>;
export type FlexibleOffersGlobalScalarConfigQueryResult = Apollo.QueryResult<
    FlexibleOffersGlobalScalarConfigQueryOperationResult,
    FlexibleOffersGlobalScalarConfigQueryVariables
>;
export const UpdateFoScalarConfigParameterDocument = gql`
    mutation updateFOScalarConfigParameter($key: FlexibleOffersRuntimeConfigScalarKeys!, $value: Int!) {
        configEntryPutScalarParameters(RuntimeConfigEntry: { key: $key, value: $value, ttl: 60 })
    }
`;
export type UpdateFoScalarConfigParameterMutationFn = Apollo.MutationFunction<
    UpdateFoScalarConfigParameterMutationOperationResult,
    UpdateFoScalarConfigParameterMutationVariables
>;

/**
 * __useUpdateFoScalarConfigParameterMutation__
 *
 * To run a mutation, you first call `useUpdateFoScalarConfigParameterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFoScalarConfigParameterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFoScalarConfigParameterMutation, { data, loading, error }] = useUpdateFoScalarConfigParameterMutation({
 *   variables: {
 *      key: // value for 'key'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateFoScalarConfigParameterMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateFoScalarConfigParameterMutationOperationResult,
        UpdateFoScalarConfigParameterMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateFoScalarConfigParameterMutationOperationResult,
        UpdateFoScalarConfigParameterMutationVariables
    >(UpdateFoScalarConfigParameterDocument, options);
}
export type UpdateFoScalarConfigParameterMutationHookResult = ReturnType<
    typeof useUpdateFoScalarConfigParameterMutation
>;
export type UpdateFoScalarConfigParameterMutationResult =
    Apollo.MutationResult<UpdateFoScalarConfigParameterMutationOperationResult>;
export type UpdateFoScalarConfigParameterMutationOptions = Apollo.BaseMutationOptions<
    UpdateFoScalarConfigParameterMutationOperationResult,
    UpdateFoScalarConfigParameterMutationVariables
>;
export const UpdateRideDocument = gql`
    mutation UpdateRide($ride: RideInput!) {
        updateRide(ride: $ride)
    }
`;
export type UpdateRideMutationFn = Apollo.MutationFunction<
    UpdateRideMutationOperationResult,
    UpdateRideMutationVariables
>;

/**
 * __useUpdateRideMutation__
 *
 * To run a mutation, you first call `useUpdateRideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRideMutation, { data, loading, error }] = useUpdateRideMutation({
 *   variables: {
 *      ride: // value for 'ride'
 *   },
 * });
 */
export function useUpdateRideMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateRideMutationOperationResult, UpdateRideMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateRideMutationOperationResult, UpdateRideMutationVariables>(
        UpdateRideDocument,
        options,
    );
}
export type UpdateRideMutationHookResult = ReturnType<typeof useUpdateRideMutation>;
export type UpdateRideMutationResult = Apollo.MutationResult<UpdateRideMutationOperationResult>;
export type UpdateRideMutationOptions = Apollo.BaseMutationOptions<
    UpdateRideMutationOperationResult,
    UpdateRideMutationVariables
>;
