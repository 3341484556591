/* eslint-disable no-alert */
import { DEFAULT_FEE_COEFFICIENT } from "@daytrip/legacy-config";
import { PaymentProvider } from "@daytrip/legacy-enums";
import { Location } from "@daytrip/legacy-models";
import { transformValidationErrorsToArrayString } from "@daytrip/legacy-transformers";
import { CountryBankType } from "@legacy/domain/CountryBankType";
import { Currency } from "@legacy/domain/Currency";
import { VehicleType } from "@legacy/domain/VehicleType";
import { Country } from "@legacy/models/Country";
import { CountryInvoiceInfo } from "@legacy/models/CountryInvoiceInfo";
import type { NumberingTemplate } from "@legacy/models/NumberingTemplate";
import { Region } from "@legacy/models/Region";
import { VehicleTypeCosts } from "@legacy/models/VehicleTypeCosts";
import type { RetrieveRegionsOptions } from "@legacy/options/RetrieveRegionsOptions";
import autobind from "autobind-decorator";
import { plainToClass } from "class-transformer";
import type { ValidationError } from "class-validator";
import { validate } from "class-validator";
import get from "lodash/get";
import { action, computed, extendObservable, observable, toJS } from "mobx";

import type { StoreManager } from "../../container";
import { container, stores } from "../../container";
import type { CountryGroupsStore } from "../../domain/countryGroups/CountryGroupsStore";
import { globalManagementLogger } from "../../global-logger";
import { RegionOperator } from "../../operators/RegionOperator";
import { PageStore } from "../../stores/PageStore";
import { FetchDataStatus } from "../../utils/FetchDataStatus";

import type { CountryPageRouter } from "./CountryPageRouter";

const FALLBACK_LOCATION_MINIMAL_PRICE = 8;

interface PageModules {
    countryGroupsStore: CountryGroupsStore;
}

@autobind
export class CountryPageStore extends PageStore<CountryPageRouter, PageModules> {
    public onInit() {
        this.modules = {
            countryGroupsStore: container.get<StoreManager<CountryGroupsStore>>(stores.countryGroups).getStore(),
        };
    }

    @observable
    public country?: Country;

    @observable
    public editedCountry?: Country;

    @observable
    public isCountryFetching: boolean = false;

    @observable
    public isCountrySaving: boolean = false;

    @observable
    public isCountryInvalid: boolean = false;

    @observable
    public isCountryValidating: boolean = false;

    @observable
    public countryValidations: Array<ValidationError> = [];

    @observable
    public error: string | null = null;

    @observable
    public countries: Array<Country>;

    @observable
    public locations: Array<Location>;

    @observable
    public numberingTemplates: Array<NumberingTemplate> = [];

    @computed
    public get areNumberingTemplatesFetched() {
        return this.numberingTemplates.length !== 0;
    }

    @observable
    public isCountriesFetching: boolean = false;

    @observable
    public isCountriesInvalid: boolean = false;

    @observable
    public regionOperators: Array<RegionOperator>;

    @observable
    public pricingCurrency: Currency;

    @observable
    public paymentProvider: PaymentProvider = PaymentProvider.Mangopay;

    public isDataFetched(): this is CountryPageStore & CountryPageStoreDataFetched {
        return !!this.editedCountry || !!this.country;
    }

    @action
    public async onFetchData() {
        await this.fetchContent();
    }

    @action
    public async fetchContent(): Promise<void> {
        const { countryId } = this.pageRouter;

        const country = await this.rpcClient.content.retrieveCountry(countryId);

        this.bankType = country.bankType;
        this.pricingCurrency = country.pricingCurrency;
        this.regionOperators = [];
        this.paymentProvider = country.paymentProvider;
        const regions = await this.rpcClient.content.retrieveRegions({
            countryIds: [country._id],
        } as RetrieveRegionsOptions);

        await Promise.all(
            regions.map(async (region) => {
                const regionOperator = await this.defineRegionOperator(region);

                await regionOperator.fetchData();

                this.regionOperators.push(regionOperator);
            }),
        );

        // No need to fetch the numbering templates again
        if (!this.areNumberingTemplatesFetched) {
            this.fetchNumberingTemplates();
        }

        this.country = country;
    }

    @action
    public async fetchNumberingTemplates(): Promise<void> {
        this.rpcClient.invoice.getAllNumberingTemplates().then((numberingTemplates) => {
            this.numberingTemplates = numberingTemplates;
        });
    }

    @action
    public async fetchCountries(): Promise<void> {
        this.rpcClient.content.retrieveCountries({}).then((countries) => {
            this.countries = countries;
        });
    }

    @action
    public async fetchLocations(): Promise<void> {
        this.rpcClient.content.retrieveLocations({}).then((locations) => {
            this.locations = locations;
        });
    }

    public async defineRegionOperator(region?: Region): Promise<RegionOperator> {
        let isNew: boolean = false;

        // in case of new region
        if (region === undefined) {
            region = new Region();
            region.vehicleTypeCosts = plainToClass(VehicleTypeCosts, (this.country as Country).vehicleTypeCosts);
            region.timeZoneName = (this.country as Country).timeZoneName;
            region.countryId = (this.country as Country)._id;
            region.guidePrice = (this.country as Country).guidePrice;
            region.guideMinimalPrice = (this.country as Country).guideMinimalPrice;
            region.guideLocationPrice = (this.country as Country).guideLocationPrice;
            region.roundtripGuideCoefficient = (this.country as Country).roundtripGuideCoefficient;
            region.roundtripMinimumCoefficient = (this.country as Country).roundtripMinimumCoefficient;

            isNew = true;
        }

        const regionOperator = new RegionOperator({
            model: plainToClass(Region, region),
            modelConstructor: Region,
            nonObservableProperties: ["geoFencePolygon"],
            modules: null,
            data: null,
            beforeEdit: async () => {},
            onFetchData: async () => FetchDataStatus.Success,
            beforeSave: async (model: Region) => {
                const operator = this.regionOperators.find((ro) => ro.model._id === model._id) as RegionOperator;
                if (!operator) {
                    return;
                }
                if (!operator.geoFencePolygonRaw) {
                    operator.m.geoFencePolygon = null;
                } else {
                    const geoFenceCandidate = JSON.parse(operator.geoFencePolygonRaw);
                    if (geoFenceCandidate.type !== "Polygon") {
                        throw new Error("Invalid Geo JSON Polygon Type format for GeoJSON Polygon GeoFence.");
                    }
                    if (!geoFenceCandidate.coordinates?.[0]?.[0]) {
                        throw new Error("No coordinates set for GeoJSON Polygon GeoFence.");
                    }
                    operator.m.geoFencePolygon = geoFenceCandidate;
                }
            },
            onSave: async (model: Region) => {
                this.adjustVehicleTypeCosts(model);
                const operator = this.regionOperators.find((ro) => ro.model._id === model._id) as RegionOperator;
                const editedRegion = plainToClass(Region, model);
                const validationErrors = await validate(editedRegion, { skipMissingProperties: true });
                if (validationErrors.length) {
                    const validationMessages = transformValidationErrorsToArrayString(validationErrors).join("\n");
                    throw new Error(`Validation failed. :(\n\nValidation errors:\n${validationMessages}`);
                }

                if (operator && !operator.isNew) {
                    try {
                        await this.rpcClient.content.updateRegion(editedRegion._id, editedRegion);
                    } catch (e: any) {
                        alert("Error while updating region.");
                        globalManagementLogger.error(e);
                    }
                } else if (operator.isNew) {
                    model.countryId = (this.country as Country)._id;
                    const savedRegionId = await this.rpcClient.content.createRegion(model);
                    operator.isNew = false;
                    operator.m._id = savedRegionId;
                }
            },
            validateOptions: { skipMissingProperties: true },
            isDataFetchedCondition: () => true,
        });

        regionOperator.isNew = isNew;
        regionOperator.geoFencePolygonRaw = region.geoFencePolygon?.type
            ? JSON.stringify(region.geoFencePolygon, null, 1)
            : "";

        return regionOperator;
    }

    private adjustVehicleTypeCosts(model: Region) {
        model.vehicleTypeCosts = model.vehicleTypeCosts.filter(
            (vehicleTypeCosts) =>
                vehicleTypeCosts.amortisationMinimalPrice ||
                vehicleTypeCosts.amortisationPrice ||
                vehicleTypeCosts.consumptionPrice ||
                vehicleTypeCosts.tripMinimumPrice,
        );
        for (const vehicleTypeCost of model.vehicleTypeCosts) {
            const matchingCountryVehicleTypeCost = this.country?.vehicleTypeCosts.find(
                (cost) => cost.vehicleType === vehicleTypeCost.vehicleType,
            );
            vehicleTypeCost.locationMinimalPrice =
                matchingCountryVehicleTypeCost?.locationMinimalPrice ?? FALLBACK_LOCATION_MINIMAL_PRICE;
        }
    }

    @action
    public async countryEditValidate() {
        const classedCountry = plainToClass(Country, this.editedCountry);
        this.countryValidations = await validate(classedCountry, { skipMissingProperties: true });
        return this.countryValidations;
    }

    @action
    public async countryEditSave() {
        const editedCountry = toJS(this.editedCountry);
        if (!editedCountry) {
            alert("No edited country!");
            return;
        }

        try {
            this.isCountrySaving = true;
            editedCountry.bankType = this.bankType;

            if (editedCountry.pricingCurrency !== this.pricingCurrency) {
                alert(
                    "Are you sure about this change? Currency will be changed for all routes in this pricing country as well!",
                );
            }
            editedCountry.pricingCurrency = this.pricingCurrency;
            editedCountry.paymentProvider = this.paymentProvider;
            const validation = await this.countryEditValidate();

            const isEnglishNameValid = this.countries?.some(
                (countryData) =>
                    !(
                        countryData._id !== editedCountry._id &&
                        countryData.englishName === editedCountry.englishName &&
                        countryData.isoCode === editedCountry.isoCode &&
                        countryData.localName === editedCountry.localName &&
                        countryData.timeZoneName === editedCountry.timeZoneName
                    ),
            );

            if (validation.length > 0 || !isEnglishNameValid) {
                const validationMessages = transformValidationErrorsToArrayString(validation).join("\n");
                alert(`Validation failed. :(\n\nValidation errors:\n${validationMessages}`);
                this.isCountrySaving = false;
                return;
            }

            if (this.country === undefined) {
                const countryId = await this.rpcClient.content.createCountry(editedCountry);
                this.pageRouter.gotoCountryPage(countryId);
                this.country = editedCountry;
                this.isCountrySaving = false;
                return;
            } else {
                await this.rpcClient.content.updateCountry(editedCountry._id, editedCountry);
                this.country = editedCountry;
                this.isCountrySaving = false;
                this.editedCountry = undefined;
                return;
            }
        } catch (e: any) {
            alert(`Not saved. Error: ${e}`);
            this.isCountrySaving = false;
            return;
        }
    }

    @action
    public countryEditCancelOrGoBack(): Promise<any> {
        if (this.country === undefined) {
            return Promise.resolve(this.pageRouter.routerStore.go(-1));
        }

        this.editedCountry = undefined;
        this.countryValidations = [];
        this.paymentProvider = this.country.paymentProvider;
        this.pricingCurrency = this.country.pricingCurrency;
        this.bankType = this.country.bankType;
        return Promise.resolve();
    }

    @action
    public countryEdit() {
        this.editedCountry = toJS(this.country);
    }

    @action
    public countryUpdateIsoCode(isoCode: string) {
        this.editedCountry!.isoCode = isoCode;
    }

    @action
    public countryUpdateLocalName(localName: string) {
        this.editedCountry!.localName = localName;
    }

    @action
    public countryUpdateEnglishName(englishName: string) {
        this.editedCountry!.englishName = englishName;
    }

    @action
    public countryUpdateTimeZoneName(timeZoneName: string) {
        this.editedCountry!.timeZoneName = timeZoneName;
    }

    @action
    public countryUpdateCallingCode(callingCode: string) {
        this.editedCountry!.callingCode = callingCode;
    }

    @action
    public countryUpdateEmergencyPhone(emergencyPhone: string) {
        this.editedCountry!.emergencyPhone = emergencyPhone;
    }

    @action
    public countryUpdateApplyTollPriceCoefficient(applyTollPriceCoefficient: boolean) {
        this.editedCountry!.applyTollPriceCoefficient = applyTollPriceCoefficient;
    }

    @observable
    public bankType = CountryBankType.IBAN;

    @action
    public async countryUpdateBankType(bankType: CountryBankType) {
        this.bankType = bankType;
    }

    @action
    public countryUpdateDriverPrice(guidePrice: number) {
        if (this.editedCountry) {
            this.editedCountry.guidePrice = guidePrice;
            this.editedCountry.guideMinimalPrice = 5 * guidePrice;
        }
    }

    @action
    public countryUpdateDriverMinimalPrice(driverMinimalPrice: number) {
        this.editedCountry!.guideMinimalPrice = driverMinimalPrice;
    }

    @action
    public countryUpdateDriverLocationPrice(guideLocationPrice: number) {
        this.editedCountry!.guideLocationPrice = guideLocationPrice;
    }

    @action
    public countryUpdateRoundtripGuideCoefficient(roundtripGuideCoefficient: number) {
        this.editedCountry!.roundtripGuideCoefficient = roundtripGuideCoefficient;
    }

    @action
    public countryUpdateRoundtripMinimumCoefficient(roundtripMinimumCoefficient: number) {
        this.editedCountry!.roundtripMinimumCoefficient = roundtripMinimumCoefficient;
    }

    @action
    public countryUpdateTaxFeeCoefficient(taxFeeCoefficient: number) {
        this.editedCountry!.taxFeeCoefficient = taxFeeCoefficient;
    }

    @action
    public countryUpdateAccomodationCost(accomodationCost: number) {
        this.editedCountry!.accomodationCost = accomodationCost;
    }

    @action
    public countryUpdateComboDiscountCoefficient(comboDiscountCoefficient: number) {
        this.editedCountry!.comboDiscountCoefficient = comboDiscountCoefficient;
    }

    @action
    public setVehicleTypeCosts(vehicleType: VehicleType, priceName: keyof VehicleTypeCosts, value: number) {
        if (!this.editedCountry) {
            return;
        }

        let vehicleTypeCostsIndex = this.editedCountry.vehicleTypeCosts.findIndex(
            (vtc) => vtc.vehicleType === vehicleType,
        );

        if (vehicleTypeCostsIndex === -1) {
            vehicleTypeCostsIndex = this.editedCountry.vehicleTypeCosts.length;
            this.editedCountry.vehicleTypeCosts.push({
                vehicleType,
                tripMinimumPrice: 0,
                amortisationMinimalPrice: 0,
                amortisationPrice: 0,
                consumptionPrice: 0,
                locationMinimalPrice: 0,
                feeCoefficient: DEFAULT_FEE_COEFFICIENT,
            });
        } else {
            // mobx observable array does not trigger re-rendering when array item is updated through direct assignment
            // we need to use observable array push/splice method to trigger re-rendering
            this.editedCountry.vehicleTypeCosts.splice(vehicleTypeCostsIndex, 1, {
                ...this.editedCountry.vehicleTypeCosts[vehicleTypeCostsIndex],
                [priceName]: value,
            });
            this.removeEmptyVehicleTypeCosts();
        }
    }

    @action
    public removeEmptyVehicleTypeCosts() {
        this.editedCountry.vehicleTypeCosts = this.editedCountry.vehicleTypeCosts.filter(
            (vehicleTypeCosts) =>
                vehicleTypeCosts.amortisationMinimalPrice ||
                vehicleTypeCosts.amortisationPrice ||
                vehicleTypeCosts.consumptionPrice ||
                vehicleTypeCosts.locationMinimalPrice ||
                vehicleTypeCosts.tripMinimumPrice,
        );
    }

    @action
    public countryUpdateVehicleTypeFeeCoefficient(vt: VehicleType, feeCoefficient: number) {
        this.setVehicleTypeCosts(vt, "feeCoefficient", feeCoefficient);
    }

    @action
    public countryUpdateVehicleTypeTripMinimumPrice(vt: VehicleType, tripMinimumPrice: number) {
        this.setVehicleTypeCosts(vt, "tripMinimumPrice", tripMinimumPrice);
    }

    @action
    public countryUpdateVehicleTypeAmortisationMinimalPrice(
        vehicleType: VehicleType,
        amortisationMinimalPrice: number,
    ) {
        this.setVehicleTypeCosts(vehicleType, "amortisationMinimalPrice", amortisationMinimalPrice);
    }

    @action
    public countryUpdateVehicleTypeAmortisationPrice(vehicleType: VehicleType, amortisationPrice: number) {
        this.setVehicleTypeCosts(vehicleType, "amortisationPrice", amortisationPrice);
    }

    @action
    public countryUpdateVehicleTypeConsumptionPrice(vehicleType: VehicleType, consumptionPrice: number) {
        this.setVehicleTypeCosts(vehicleType, "consumptionPrice", consumptionPrice);
    }

    @action
    public countryUpdateVehicleTypeLocationMinimalPrice(vehicleType: VehicleType, locationMinimalPrice: number) {
        this.setVehicleTypeCosts(vehicleType, "locationMinimalPrice", locationMinimalPrice);
    }

    @action
    public addInvoiceInfo() {
        if (this.editedCountry) {
            extendObservable(this.editedCountry, { invoiceInfo: extendObservable({}, new CountryInvoiceInfo()) });
        }
    }

    @action
    public setInvoiceInfoIsVat(isVat: boolean) {
        if (this.editedCountry && this.editedCountry.invoiceInfo) {
            this.editedCountry.invoiceInfo.isVat = isVat;
        }
    }

    @action
    public setInvoiceInfoIsReverseCharge(isReverseCharge: boolean) {
        if (this.editedCountry && this.editedCountry.invoiceInfo) {
            this.editedCountry.invoiceInfo.isReverseCharge = isReverseCharge;
        }
    }

    @action
    public setInvoiceInfoVatPercentage(vatPercentage: number) {
        if (this.editedCountry && this.editedCountry.invoiceInfo) {
            this.editedCountry.invoiceInfo.vatPercentage = vatPercentage;
        }
    }

    @action
    public setInvoiceInfoInvoiceCurrency(invoiceCurrency: Currency) {
        if (this.editedCountry && this.editedCountry.invoiceInfo) {
            this.editedCountry.invoiceInfo.invoiceCurrency = invoiceCurrency;
        }
    }

    @action
    public setCountryCurrency(currency: Currency) {
        this.pricingCurrency = currency;
    }

    @action
    public setPaymentProvider(paymentProvider: PaymentProvider) {
        this.paymentProvider = paymentProvider;
    }

    @computed
    public get selectedNumberingTemplate(): NumberingTemplate | undefined {
        const country = this.editedCountry || this.country;

        if (!country?.invoiceInfo) {
            return undefined;
        }

        return this.numberingTemplates.find(
            (nt) => nt._id === (country.invoiceInfo as CountryInvoiceInfo).numberingTemplateId,
        );
    }

    @action
    public setInvoiceInfoNumberingTemplate(numberingTemplateId: string) {
        if (this.editedCountry && this.editedCountry.invoiceInfo) {
            this.editedCountry.invoiceInfo.numberingTemplateId = numberingTemplateId;
        }
    }

    private flattenValidations(
        validations: Array<ValidationError>,
        staticPrefix: string = " ",
        prefix: string = " ",
        level: number = 1,
    ) {
        let result = "";

        validations.forEach((validation) => {
            const csKeys = Object.keys(validation?.constraints || {});

            csKeys.forEach((key) => {
                if (get(validation.constraints, key)) {
                    result += `${staticPrefix}${prefix.repeat(level)}${key}: ${get(validation.constraints, key)}\n`;
                }
            });

            if (validation.children) {
                result += this.flattenValidations(validation.children, staticPrefix, prefix, level + 1);
            }
        });

        return result;
    }

    public async addNewRegion() {
        const regionOperator = await this.defineRegionOperator();
        regionOperator.editedModel = regionOperator.model;
        this.regionOperators.unshift(regionOperator);
    }

    public async removeRegion(regionId: string) {
        await this.rpcClient.content.removeRegion(regionId);

        // remove operator from region operators
        this.regionOperators.splice(
            this.regionOperators.indexOf(this.regionOperators.find((ro) => ro.m._id === regionId) as RegionOperator),
            1,
        );
    }
}

export interface CountryPageStoreDataFetched {
    country: Country;
    editedCountry?: Country;
    countries: Array<Country>;
}
