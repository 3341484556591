import { Exclude, Expose, Transform } from "class-transformer";
import { IsArray, IsBoolean, IsDate, IsNumber, IsString, Validate } from "class-validator";

import type { DriverStatus } from "../domain/DriverStatus";
import { IsUUIDOrNull } from "../Validators/IsUUIDOrNull";

import { RetrieveUsersOptions } from "./RetrieveUsersOptions";

@Exclude()
export class RetrieveDriversOptions extends RetrieveUsersOptions {
    @Expose()
    @IsArray()
    public ids?: Array<string>;

    @Expose()
    @IsDate()
    public availableDate?: Date;

    @Expose()
    @IsArray()
    public originLocationIds?: Array<string>;

    @IsArray()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : (value as Array<typeof value>).map((item) => Number(item))))
    public statusIn?: Array<DriverStatus>;

    @IsArray()
    @Expose()
    @Transform((value) => (value == undefined ? undefined : (value as Array<typeof value>).map((item) => String(item))))
    public managerIds?: Array<string>;

    @IsBoolean()
    @Expose()
    public availabilitySet?: boolean;

    @IsBoolean()
    @Expose()
    public availableOnly?: boolean;

    @IsBoolean()
    @Expose()
    public includeCompanies?: boolean = false;

    @IsString()
    @Expose()
    public driversCompanyUserId?: string;

    @IsBoolean()
    @Expose()
    public isCompanyDriver?: boolean;

    @IsBoolean()
    @Expose()
    public isLuxuryDriver?: boolean;

    @IsBoolean()
    @Expose()
    public isTCPDriver?: boolean;

    @IsNumber()
    @Expose()
    public payoutType?: number;

    @IsString()
    @Expose()
    public vehicleModelIds?: string[];

    @IsArray()
    @Expose()
    @Validate(IsUUIDOrNull, { each: true })
    public regionIdIn?: (string | null)[];

    @IsBoolean()
    @Expose()
    public isActiveInLast7Days?: boolean;

    @IsBoolean()
    @Expose()
    public isWillingToRentEnabled?: boolean;
}
