import { US_COUNTRY_ID } from "@daytrip/legacy-config";
import type { SimpleDriverWithVehicles } from "@legacy/domain/SimpleDriverWithVehicles";
import type { SimpleUser } from "@legacy/domain/SimpleUser";
import type { Country } from "@legacy/models/Country";
import type { Region } from "@legacy/models/Region";
import type { RetrieveDriversOptions } from "@legacy/options/RetrieveDriversOptions";
import { isUndefinedOrNull } from "@legacy/utils";
import autobind from "autobind-decorator";
import { validate } from "class-validator";
import { action, observable } from "mobx";
import type { Option } from "react-select-legacy";

import { PageStore } from "../../stores/PageStore";
import { processRouterRegionValues } from "../../utils/prepareRegionOptions";

import type { DriversPageRouter } from "./DriversPageRouter";

@autobind
export class DriversPageStore extends PageStore<DriversPageRouter, null> {
    @observable
    public drivers: Array<SimpleDriverWithVehicles>;

    @observable
    public driversCount: number = 0;

    @observable
    public countries?: Array<Country>;

    @observable
    public regions?: Array<Region>;

    @observable
    public managers?: Array<SimpleUser>;

    @observable
    public isLoading: boolean = false;

    @action
    public async onFetchData() {
        await this.fetchDrivers();
        this.fetchContent();
    }

    @action
    public async fetchContent() {
        [this.countries, this.regions, this.managers] = await Promise.all([
            this.rpcClient.content.retrieveCountries({}),
            this.rpcClient.content.retrieveRegions({ countryIds: [US_COUNTRY_ID] }),
            this.rpcClient.user.getSimpleRegionalManagers(),
        ]);

        const queryVehicleModelIds = this.pageRouter.vehicleModels;
        if (queryVehicleModelIds?.length) {
            const vehicleModels = await this.rpcClient.vehicle.retrieveVehicleModelsById(queryVehicleModelIds);
            this.selectedVehicleModelOptions = vehicleModels.map((model) => ({
                value: model.modelId,
                label: model.name,
            }));
        }
    }

    @action
    public async fetchDrivers(): Promise<void> {
        this.isLoading = true;
        this.drivers = [];

        const options = {
            skip: this.pageRouter.skip,
            limit: this.pageRouter.limit,
            sortBy: this.pageRouter.sortBy,
            sortDirection: this.pageRouter.sortDirection,
            searchString: this.pageRouter.search,
            managerIds: this.pageRouter.managerIds,
            countryIds: this.pageRouter.countryIds,
            regionIdIn: processRouterRegionValues(this.pageRouter.regionIds),
            isDriver: true,
            statusIn: this.pageRouter.statuses,
            isCompanyDriver: this.pageRouter.isCompanyDriver,
            isLuxuryDriver: this.pageRouter.isLuxuryDriver,
            isTCPDriver: this.pageRouter.isTCPDriver,
            payoutType: this.pageRouter.payoutType,
            hasMangopayInformationApproved: this.pageRouter.hasMangopayInformationApproved,
            vehicleModelIds: this.pageRouter.vehicleModels,
            isActiveInLast7Days: this.pageRouter.isActiveInLast7Days,
            isWillingToRentEnabled: this.pageRouter.isWillingToRentEnabled,
        } as RetrieveDriversOptions;

        const optionsValidationErrors = await validate(options, { skipMissingProperties: true });
        if (optionsValidationErrors.length > 0) {
            return Promise.reject(new Error("Validation error"));
        }

        this.drivers = await this.rpcClient.driver.retrieveDriversForDriversPage(options);
        this.driversCount = await this.rpcClient.driver.retrieveDriversCount(options);

        this.isLoading = false;
    }

    public displayOnMap() {
        this.pageRouter.displayOnMap();
    }

    @observable
    public isEmailsLoading = false;

    @observable
    public isExportEmailsModalOpened = false;

    @observable
    public exportedEmails = "";

    @action
    public async exportEmails(): Promise<void> {
        this.isExportEmailsModalOpened = true;
        this.isEmailsLoading = true;

        const options = {
            searchString: this.pageRouter.search,
            managerIds: isUndefinedOrNull(this.pageRouter.managerIds) ? undefined : this.pageRouter.managerIds,
            countryIds: isUndefinedOrNull(this.pageRouter.countryIds) ? undefined : this.pageRouter.countryIds,
            regionIdIn: processRouterRegionValues(this.pageRouter.regionIds),
            isDriver: true,
            isCompanyDriver:
                this.pageRouter.isCompanyDriver === undefined ? undefined : this.pageRouter.isCompanyDriver,
            isLuxuryDriver: this.pageRouter.isLuxuryDriver === undefined ? undefined : this.pageRouter.isLuxuryDriver,
            isTCPDriver: isUndefinedOrNull(this.pageRouter.isTCPDriver) ? undefined : this.pageRouter.isTCPDriver,
            statusIn: isUndefinedOrNull(this.pageRouter.statuses) ? undefined : this.pageRouter.statuses,
        } as RetrieveDriversOptions;

        const optionsValidationErrors = await validate(options, { skipMissingProperties: true });
        if (optionsValidationErrors.length > 0) {
            return Promise.reject(new Error("Validation error"));
        }

        const arrayOfEmails = await this.rpcClient.driver.retrieveEmailsForExport(options);

        this.exportedEmails = arrayOfEmails.join("; ");

        this.isEmailsLoading = false;
    }

    @action
    public closeExportEmailsModal(): void {
        this.isExportEmailsModalOpened = false;
    }

    public async loadVehicleModels(search: string) {
        if (search.length <= 2) {
            return { options: [], complete: false };
        }

        const vehicleModels = await this.rpcClient.vehicle.searchVehicleModels(search);

        return {
            options: vehicleModels.map((vehicleModel) => ({
                value: vehicleModel.modelId,
                label: vehicleModel.name,
            })),
            complete: true,
        };
    }

    @observable
    public selectedVehicleModelOptions: Option[] = [];

    @action
    public selectVehicleModels(selectedOptions: Option<string>[]): void {
        this.selectedVehicleModelOptions = selectedOptions;
        this.pageRouter.filterVehicleModel(selectedOptions?.map((option) => option.value) ?? []);
    }

    public isDataFetched(): this is DriversPageStore & { drivers: SimpleDriverWithVehicles[] } {
        return !!this.drivers;
    }
}
