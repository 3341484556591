import { VehicleStatus, VehicleTypesCapacity } from "@daytrip/legacy-enums";
import { transformPassengersCountToVehicleType } from "@daytrip/legacy-transformers";

import type { VehicleInfo } from "../domain/VehicleInfo";
import { VehicleType } from "../domain/VehicleType";
import type { VehicleModel } from "../models/VehicleModel";

type RequiredVehicleInfo = Pick<
    VehicleInfo,
    "status" | "seatsCount" | "approvedForCountryIds" | "luxuryForCountryIds" | "isLuxury"
>;

export function filterSuitableVehicleInfo(
    vehicleTypeToSuit: VehicleType,
    requiredCapacity: number,
    approvedForCountryIds: string[],
): (vehicleInfo: RequiredVehicleInfo) => boolean {
    const filterFunction = filterSuitableVehicleData(vehicleTypeToSuit, requiredCapacity, approvedForCountryIds);
    return (vehicleInfo: RequiredVehicleInfo) =>
        filterFunction(vehicleInfo) && vehicleInfo.status === VehicleStatus.Accepted;
}

export function filterSuitableVehicleModel(
    vehicleTypeToSuit: VehicleType,
    requiredCapacity: number,
    approvedForCountryIds: string[],
): (vehicleModel: VehicleModel) => boolean {
    const filterFunction = filterSuitableVehicleData(vehicleTypeToSuit, requiredCapacity, approvedForCountryIds);
    return (vehicleModel: VehicleModel) => filterFunction(vehicleModel) && vehicleModel.disabledAt === undefined;
}

export function filterSuitableVehicleData(
    vehicleTypeToSuit: VehicleType,
    requiredCapacity: number,
    approvedForCountryIds: string[],
): (vehicleData: VehicleModel | RequiredVehicleInfo) => boolean {
    const isLuxury = vehicleTypeToSuit === VehicleType.LuxurySedan;

    return (vehicleData: VehicleModel | RequiredVehicleInfo) => {
        for (const countryId of approvedForCountryIds) {
            if (vehicleData.approvedForCountryIds.indexOf(countryId) === -1) {
                return false;
            }
        }

        if (vehicleData.seatsCount < requiredCapacity) {
            return false;
        }

        if (isLuxury) {
            if (vehicleData.luxuryForCountryIds && vehicleData.luxuryForCountryIds.length > 0) {
                for (const countryId of approvedForCountryIds) {
                    if (vehicleData.luxuryForCountryIds.indexOf(countryId) === -1) {
                        return false;
                    }
                }
            }

            return isLuxury === vehicleData.isLuxury;
        }

        const typeOfVehicleTestedForSuitability = transformPassengersCountToVehicleType(
            vehicleData.seatsCount,
            false,
            false,
        );

        return VehicleTypesCapacity[typeOfVehicleTestedForSuitability] >= VehicleTypesCapacity[vehicleTypeToSuit];
    };
}

export const isVehicleTypeCompatible = (vehicleType: VehicleType, orderVehicleType: VehicleType) => {
    if (orderVehicleType === VehicleType.LuxurySedan) {
        return vehicleType === VehicleType.LuxurySedan;
    }

    // We assume that the driver is able to rent Luxury Sedan with 3 seats in case of such Sedan/SedanLite order.
    if (vehicleType === VehicleType.LuxurySedan) {
        return [VehicleType.Sedan, VehicleType.SedanLite].includes(orderVehicleType);
    }

    return VehicleTypesCapacity[vehicleType] >= VehicleTypesCapacity[orderVehicleType];
};

export const isDriverWillingToRentSuitableVehicle = (
    rentedVehicleTypes: VehicleType[],
    orderVehicleType: VehicleType,
) => {
    return rentedVehicleTypes.some((rentedVehicleType) => {
        return isVehicleTypeCompatible(rentedVehicleType, orderVehicleType);
    });
};
