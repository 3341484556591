import type { DriverFaq } from "@daytrip/api";
import { action, observable } from "mobx";
import { v4 as uuid } from "uuid";

import { IMGIX_API_KEY, IMGIX_ASSETS_URL, IMGIX_SOURCE_ID, IMGIX_UPLOAD_URL } from "../../config.management";
import { ModelOperator } from "../../operators/ModelOperator";
import type { ModelOperatorOptions } from "../../operators/ModelOperatorOptions";

interface DriverFaqOperatorOptions extends ModelOperatorOptions<DriverFaq, null, null> {
    isNew: boolean;
    isDeleted: boolean;
}

export class DriverFaqOperator extends ModelOperator<DriverFaq, DriverFaqOperatorOptions, null, null, null> {
    public constructor(options: DriverFaqOperatorOptions) {
        super(options);

        this.isNew = options.isNew;
        this.isDeleted = options.isDeleted;
    }

    @observable
    public isImageUploading: boolean = false;

    @observable
    public isNew: boolean = false;

    @observable
    public isDeleted: boolean = false;

    @action
    public async uploadImageToImgix(file: File) {
        const extension = file.name.split(".").pop();
        const filePathName = `${uuid()}.${extension}`;
        const endpoint = `sources/${IMGIX_SOURCE_ID}/upload/${filePathName}`;
        let assetUrl = "";
        try {
            await fetch(`${IMGIX_UPLOAD_URL}/${endpoint}`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${IMGIX_API_KEY}`,
                },
                body: file,
            });
            assetUrl = `${IMGIX_ASSETS_URL}/${filePathName}?w=700&h=300&fit=crop`;
        } catch (error: any) {
            alert(`Oh, something is wrong. :(\nError:\n${error.message}`);
        }
        return assetUrl;
    }
}
