import { Vehicle } from "@legacy/models/Vehicle";
import { VehicleMake } from "@legacy/models/VehicleMake";
import { VehicleModel } from "@legacy/models/VehicleModel";
import { action, observable } from "mobx";

import { DocumentTypeOperator } from "./DocumentTypeOperator"; // change inport
import { ModelOperator } from "./ModelOperator";
import { ModelOperatorOptions } from "./ModelOperatorOptions";

interface VehicleOperatorOptions extends ModelOperatorOptions<Vehicle, null, VehicleOperatorData> {
    documentTypes?: Array<DocumentTypeOperator>;
}

interface VehicleOperatorData {
    vehicleMake?: VehicleMake;
    vehicleModel?: VehicleModel;
}

interface VehicleOperatorDataFetched {
    vehicleMake: VehicleMake;
    vehicleModel: VehicleModel;
}

export class VehicleOperator extends ModelOperator<
    Vehicle,
    VehicleOperatorOptions,
    null,
    VehicleOperatorData,
    VehicleOperatorDataFetched
> {
    @observable
    public vehicleMake?: VehicleMake;

    @observable
    public vehicleModel?: VehicleModel;

    @observable
    public documentTypeOperators?: Array<DocumentTypeOperator>;

    @observable
    public isNew = false;

    public constructor(options: VehicleOperatorOptions) {
        super(options);

        this.vehicleMake = this.data.vehicleMake;
        this.vehicleModel = this.data.vehicleModel;
        this.documentTypeOperators = options.documentTypes;
    }

    @action.bound
    public setVehicleMake(make: VehicleMake) {
        this.vehicleMake = make;
    }

    @action.bound
    public setVehicleModel(model: VehicleModel | undefined) {
        this.vehicleModel = model;
    }

    @action.bound
    public async approveVehicle(): Promise<void> {
        await this.rpcClient.vehicle.approveVehicle(this.m._id);
        this.model = await this.rpcClient.vehicle.retrieveVehicle(this.m._id);
    }

    @observable
    public objectDeclinationReason: string;

    @action.bound
    public changeVehicleDeclinationReason(value: string): void {
        this.objectDeclinationReason = value;
    }

    @action.bound
    public async declineVehicle(): Promise<void> {
        try {
            await this.rpcClient.vehicle.declineVehicle(this.m._id, this.objectDeclinationReason);
            this.model = await this.rpcClient.vehicle.retrieveVehicle(this.m._id);
            this.objectDeclinationReason = "";
        } catch (e: any) {
            alert("Not declined, contact admin.");
        }
    }

    @action.bound
    public async deleteVehicle(): Promise<void> {
        try {
            await this.rpcClient.vehicle.deleteVehicle(this.m._id);
            this.edit((o) => (o.deletedAt = new Date()));
        } catch (e: any) {
            alert("not deleted, contact admin.");
        }
    }
}
