/* eslint-disable no-alert */

import { API_OPEN_DRIVER_DOCUMENT_URL } from "@daytrip/legacy-config";
import { transformValidationErrorsToArrayString } from "@daytrip/legacy-transformers";
import { isUndefinedOrNull } from "@daytrip/utils";
import { BillingInformation } from "@legacy/domain/BillingInformation";
import { BillingInformationType } from "@legacy/domain/BillingInformationType";
import { DocumentSubject } from "@legacy/domain/DocumentSubject";
import type { FilePath } from "@legacy/domain/FilePath";
import { PayoutType } from "@legacy/domain/PayoutType";
import type { SimpleCountry } from "@legacy/domain/SimpleCountry";
import { Document } from "@legacy/models/Document";
import { DocumentType } from "@legacy/models/DocumentType";
import { User } from "@legacy/models/User";
import autobind from "autobind-decorator";
import { plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { action, computed, observable, reaction } from "mobx";

import { globalManagementLogger } from "../../global-logger";
import { BillingInformationOperator } from "../../operators/BillingInformationOperator";
import { DocumentOperator } from "../../operators/DocumentOperator";
import { DocumentTypeOperator } from "../../operators/DocumentTypeOperator";
import { routes } from "../../routes";
import { PageStore } from "../../stores/PageStore";
import { getVatErrorMessage } from "../../utils/getVatValidationMessage";

import type { BillingInformationPageRouter } from "./BillingInformationPageRouter";

@autobind
export class BillingInformationPageStore extends PageStore<BillingInformationPageRouter, {}> {
    @observable
    public user: User;

    @observable
    public billingInformation: Array<BillingInformationOperator>;

    @observable
    public simpleCountries: Array<SimpleCountry>;

    @observable
    public isDropdownOpened: boolean = false;

    @observable
    public selectedBillingInformationId?: string;

    @observable
    public documentTypesForNaturalPerson?: Array<DocumentType>;

    @observable
    public documentTypesForLegalPerson?: Array<DocumentType>;

    @observable
    public documentTypesForSoletrader?: Array<DocumentType>;

    @observable
    public documentTypes?: Array<DocumentType>;

    @observable
    public isLoadingDocuments: boolean = false;

    @observable
    public openedBillingInformationIsValid: boolean = false;

    @action
    public async onFetchData() {
        let { userId } = this.pageRouter;
        const { userEmail } = this.pageRouter;

        if (this.isDriverOrCompany) {
            userId = this.authenticationStore.userJWT?.userId;
        }

        try {
            if (userId) {
                this.user = plainToClass(User, await this.rpcClient.user.retrieveUser(userId));
            } else if (userEmail) {
                this.user = plainToClass(User, await this.rpcClient.user.retrieveUserByEmail(userEmail));
            }
        } catch (e: any) {
            return globalManagementLogger.error(e);
        }

        if (!this.simpleCountries) {
            const simpleCountries = await this.rpcClient.content.retrieveSimpleCountries({});
            this.simpleCountries = simpleCountries;
        }

        this.billingInformation = this.user.billingInformation.map((bi) => {
            const mangopayInformation = this.user.mangopayInformation.find((mi) => mi.billingInformationId === bi._id);
            const isMangopayType = this.user.payoutType === PayoutType.Mangopay;
            const billingInformationOperator = new BillingInformationOperator({
                modelConstructor: BillingInformation,
                model: plainToClass(BillingInformation, bi),
                data: {
                    simpleCountries: this.simpleCountries,
                    mangopayInformation,
                    userId: this.user._id,
                    mangopayUserId: this.user.activeMangopayInformation
                        ? this.user.activeMangopayInformation.mangopayId
                        : undefined,
                },
                modules: null,
                onSave: async (editedModel) => {
                    if (editedModel.deletedAt) {
                        alert("Deleted Billing Information cannot be updated. Please create a new one.");
                        return;
                    }

                    await this.validateBillingInformation(editedModel);

                    if (editedModel.uboDeclaration) {
                        editedModel.uboDeclaration.ubos = billingInformationOperator.uboOperators.map((ubo) => ubo.m);
                    }

                    try {
                        const operator = this.billingInformation.find((o) => o.model._id === editedModel._id);
                        const mangopayInformationUpdate = operator?.bankAccountId
                            ? { bankAccountId: operator?.bankAccountId }
                            : undefined;
                        await this.rpcClient.billingInformation.updateBillingInformation(
                            this.user._id,
                            editedModel,
                            isMangopayType,
                            mangopayInformationUpdate,
                        );
                    } catch (e: any) {
                        // meaning that error most likely comes from Mangopay
                        if (e.Message) {
                            const errorMessage =
                                e.Message + e.errors
                                    ? `:\n\n${Object.keys(e.errors)
                                          .map((key: string) => `${key}: ${e.errors[key]}`)
                                          .join("\n")}`
                                    : "";

                            alert(errorMessage);
                        } else {
                            alert(
                                "There was an error during update of billing information. It may be connected with Mangopay update. Please contact driver support.",
                            );
                        }
                    }

                    await this.onFetchData();
                },
                onSetActive: async (editedModel) => {
                    try {
                        await this.rpcClient.billingInformation.setActiveBillingInformation(
                            this.user._id,
                            editedModel._id,
                        );
                    } catch (e: any) {
                        alert(
                            "There was an error setting the billing information as Active. Please contact driver support.",
                        );
                    }

                    await this.onFetchData();
                },
            });

            return billingInformationOperator;
        });

        // in case CA associated with Active BI was declined but there are other non declined CA, we have to select on BI as opened
        if (
            !this.selectedBillingInformationId &&
            this.billingInformation.length &&
            !this.billingInformation.find((bi) => bi.m.isActive)
        ) {
            this.selectedBillingInformationId = this.billingInformation[0].m._id;
        }

        if (this.openedBillingInformation) {
            await this.validateOpenedBillingInformation();
            this.openedBillingInformation.documentTypeOperators = await this.fetchDocuments(
                this.openedBillingInformation.m,
            );
        }
    }

    private async validateBillingInformation(editedModel: BillingInformation) {
        const model = plainToClass(BillingInformation, editedModel);
        const validationErrors = await validate(model);
        if (validationErrors.length) {
            const validationMessages = transformValidationErrorsToArrayString(validationErrors).join("\n");
            throw new Error(`Validation failed. :(\n\nValidation errors:\n${validationMessages}`);
        }
    }

    @action
    public async fetchDocuments(billingInformation: BillingInformation): Promise<Array<DocumentTypeOperator>> {
        if (!this.user) {
            return [];
        }

        const biTypeToDocumentSubjectMap = {
            [BillingInformationType.Natural]: DocumentSubject.NaturalPerson,
            [BillingInformationType.Legal]: DocumentSubject.LegalPerson,
            [BillingInformationType.Soletrader]: DocumentSubject.Soletrader,
        };

        this.documentTypes = plainToClass(
            DocumentType,
            await this.rpcClient.document.retrieveDocumentTypes({
                countryIds: [this.user.countryId as string],
                subject: biTypeToDocumentSubjectMap[billingInformation.type],
            }),
        );

        return this.documentTypes.map((documentType) => {
            const document = billingInformation.documents
                // Since documents don't have any kind of "uploaded at" field, the only way to tell
                // the latest document - is to compare externalDocumentId from Mangopay
                // Prod id looks like "kyc_1234567" and staging is looks like "1234567"
                // So we need to sort it based on string comparison, not casting it to Number
                .sort((a, b) => (b.externalDocumentId || "").localeCompare(a.externalDocumentId || ""))
                .find((d) => d.documentTypeId === documentType._id && !d.deletedAt);

            const dto = new DocumentTypeOperator({
                modelConstructor: DocumentType,
                model: documentType,
                documentOperator: document
                    ? new DocumentOperator({
                          modelConstructor: Document,
                          model: document,
                          data: null,
                          modules: null,
                      })
                    : undefined,
                data: null,
                modules: null,
                userId: this.user._id,
            });

            return dto;
        });
    }

    @action
    public onAddNewBillingInformation(type: BillingInformationType) {
        if (isUndefinedOrNull(this.user.payoutType)) {
            alert("PayoutType is missing from this user");
            return;
        }

        const userIdQuery = this.isDriverOrCompany ? "" : `&userId=${this.user._id}`;

        window.location.href = `/#/${routes.cooperationAgreementSign}?type=${type}${userIdQuery}`;
    }

    @action
    public async createMangopayUserByBilling(): Promise<void> {
        this.isUploadingBillingInfoToMangopay = true;
        try {
            await this.rpcClient.billingInformation.createMangopayUserByBilling(this.user._id);
        } catch (e: any) {
            alert(`Cannot create mangopay user. Reason: ${e}`);
        }
        await this.onFetchData();
        this.isUploadingBillingInfoToMangopay = false;
    }

    @action
    public async onSelectBillingInformation(billingInformationId: string): Promise<void> {
        this.selectedBillingInformationId = billingInformationId;
        this.isDropdownOpened = false;
        await this.validateOpenedBillingInformation();
    }

    @computed
    public get openedBillingInformation(): BillingInformationOperator | undefined {
        if (!this.billingInformation) {
            return undefined;
        }
        if (this.selectedBillingInformationId) {
            return this.billingInformation.find((bi) => bi.m._id === this.selectedBillingInformationId);
        }

        return this.billingInformation.find((bi) => bi.m.isActive);
    }

    private async validateOpenedBillingInformation(): Promise<void> {
        await this.openedBillingInformation?.validate();
        const validations = this.openedBillingInformation?.validations;
        this.openedBillingInformationIsValid = validations?.length === 0;
        this.openedBillingInformation?.checkIfVatIdValidationIsSupported();
        this.openedBillingInformation?.setVatValidationValues();
    }

    @computed
    public get isActiveBillingInfoAssociatedWithMangopay(): boolean | undefined {
        if (!this.user) {
            return undefined;
        }

        const activeBillingInfo = this.user.billingInformation.find((bi) => bi.isActive);

        if (!activeBillingInfo) {
            return undefined;
        }

        return this.user.mangopayInformation.findIndex((mi) => mi.billingInformationId === activeBillingInfo._id) > -1;
    }

    @computed
    public get getInvalidVatErrorMessage(): string | undefined {
        if (
            !this.user ||
            !this.openedBillingInformation ||
            this.openedBillingInformation.isEdited() ||
            !this.openedBillingInformation.isVatValidationSupported
        ) {
            return;
        }

        const vatValidation = this.openedBillingInformation.m.vatValidation;

        return getVatErrorMessage(vatValidation);
    }

    public openedBillingInformationReaction = reaction(
        () => (this.billingInformation ? this.openedBillingInformation : undefined),
        async (billingInformationOperator) => {
            if (billingInformationOperator && billingInformationOperator.documentTypeOperators.length === 0) {
                billingInformationOperator.documentTypeOperators = await this.fetchDocuments(
                    billingInformationOperator.m,
                );
            }
        },
    );

    @action
    public cancelCreateNew(): void {
        const newBillingInformationIndex = this.billingInformation.findIndex((b) => b.isNew);
        this.billingInformation.splice(newBillingInformationIndex, 1);
    }

    @action
    public toggleDropdown(): void {
        this.isDropdownOpened = !this.isDropdownOpened;
    }

    @action
    public onClickOutside(): void {
        if (this.isDropdownOpened) {
            this.isDropdownOpened = false;
        }
    }

    @observable
    public lightboxDocuments?: Array<FilePath>;

    @observable
    public lightboxInitialIndex: number = 0;

    @observable
    public lightboxDescription: string | undefined;

    @action
    public async revealLightbox(fileIds: Array<string>, title: string, index: number = 0) {
        const userId = this.user._id;
        this.lightboxInitialIndex = index;
        this.lightboxDocuments = await this.rpcClient.driver.retrieveFilesPaths(
            userId,
            fileIds,
            API_OPEN_DRIVER_DOCUMENT_URL,
        );
        this.lightboxDocuments = this.lightboxDocuments.sort((a, b) => a.url.localeCompare(b.url));
        this.lightboxDescription = title;
    }

    @observable
    public imageLightboxUrl: string | undefined;

    @action
    public revealImageLightbox(imageUrl: string | undefined, description: string | undefined) {
        this.imageLightboxUrl = imageUrl;
        this.lightboxDescription = description;
    }

    @action
    public closeLightbox() {
        this.lightboxDocuments = undefined;
        this.lightboxInitialIndex = 0;
        this.lightboxDescription = undefined;
        this.imageLightboxUrl = undefined;
    }

    public isDataFetched(): this is BillingInformationPageStore & BillingInformationPageStoreDataFetched {
        return !!this.user && !!this.billingInformation && !!this.simpleCountries;
    }

    @observable
    public isCheckingRegularApprovement: boolean = false;

    @observable
    public isUploadingBillingInfoToMangopay: boolean = false;

    @observable
    public regularApprovementChecked: boolean = false;

    @action
    public async checkIsUserApproved() {
        this.isCheckingRegularApprovement = true;

        let result: boolean = false;

        try {
            result = await this.rpcClient.mangopay.loadIsKYCValidated(this.pageRouter.userId as string);
            this.regularApprovementChecked = true;
        } catch (e: any) {
            alert(
                "Checking mangopay user status failed: billing information doesn't have correspondent mangopay information",
            );
            globalManagementLogger.error(e);
        }

        this.isCheckingRegularApprovement = false;
        return result;
    }

    @action
    public async enableEditingMode() {
        if (!this.openedBillingInformation) {
            return;
        }

        this.openedBillingInformation.edit();
        this.openedBillingInformation.validate();
    }

    // Used to disable creating natural BI
    @computed
    public get isNaturalPersonOptionHidden(): boolean {
        const userCountry = this.simpleCountries.find((country) => country._id === this.user.countryId);

        return !!(
            (userCountry?.invoiceInfo?.isVat || ["us", "ca"].includes(userCountry?.isoCode || "")) // if userCountry is VAT country, netural person should be hidden // if userCountry is US or Canada, netural person should not be visible
        );
    }

    @computed
    public get isDriverOrCompany(): boolean {
        return this.authenticationStore.isDriver || this.authenticationStore.isDriversCompany;
    }
}

export interface BillingInformationPageStoreDataFetched {
    user: User;
    billingInformation: Array<BillingInformationOperator>;
    simpleCountries: Array<SimpleCountry>;
}
