export {
    VehicleType,
    VehiclePartnerApiType,
    VehicleTypes,
    VehicleTypePartnerApiType,
    VehicleTypesCapacity,
    VehicleTypesSuitcases,
    VehicleTypesCarryOns,
    VehicleTypeDescriptions,
    VehicleModelDescriptions,
} from "@daytrip/legacy-enums";
