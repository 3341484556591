import {
    API_OPEN_DRIVERS_COMPANY_DOCUMENT_URL,
    API_UPLOAD_PROFILE_PHOTO_URL,
    MAX_FILE_UPLOAD_SIZE_IN_B,
} from "@daytrip/legacy-config";
import { Position } from "@daytrip/legacy-models";
import { LocationPosition } from "@daytrip/react-shared-components";
import type { FilePath } from "@legacy/domain/FilePath";
import type { MangopayPersonType } from "@legacy/domain/MangopayPersonType";
import type { SimpleCountry } from "@legacy/domain/SimpleCountry";
import { Driver } from "@legacy/models/Driver";
import type { User } from "@legacy/models/User";
import { isUndefinedOrNull } from "@legacy/utils";
import autobind from "autobind-decorator";
import type { ValidationError } from "class-validator";
import { action, computed, observable, toJS } from "mobx";

import { getPhoneNumberExtendedValidationMessage } from "../utils/validateUserPhoneNumber";

import type { DocumentTypeOperator } from "./DocumentTypeOperator";
import { ModelOperator } from "./ModelOperator";
import type { ModelOperatorOptions } from "./ModelOperatorOptions";

interface CompanyDriverOperatorOptions extends ModelOperatorOptions<User, null, CompanyDriverOperatorData> {
    isNew?: boolean;
    documentTypeOperators?: Array<DocumentTypeOperator>;
}

interface CompanyDriverOperatorData {
    simpleCountries?: Array<SimpleCountry>;
}

interface CompanyDriverOperatorDataFetched {
    simpleCountries: Array<SimpleCountry>;
}

@autobind
export class CompanyDriverOperator extends ModelOperator<
    User,
    CompanyDriverOperatorOptions,
    null,
    CompanyDriverOperatorData,
    CompanyDriverOperatorDataFetched
> {
    public constructor(options: CompanyDriverOperatorOptions) {
        super(options);
        this.isNew = options.isNew;
        this.documentTypeOperators = options.documentTypeOperators;
    }

    @observable
    public isNew? = false;

    @observable
    public documentTypeOperators?: Array<DocumentTypeOperator>;

    @observable
    public isProfilePhotoModalOpened = false;

    @observable
    public isImageCropperVisible = false;

    @observable
    public isAddressValid?: boolean;

    @computed
    public get getPhoneNumberValidationMessage(): string | undefined {
        const validationMessages = this.getValidationMessages("phoneNumber");

        return getPhoneNumberExtendedValidationMessage(this.m.phoneNumber, validationMessages);
    }

    @computed
    public get getWhatsappNumberValidationMessage(): string | undefined {
        const validationMessages = this.getValidationMessages("whatsappNumber");

        return getPhoneNumberExtendedValidationMessage(this.m.whatsappNumber, validationMessages, true);
    }

    @action
    public toggleProfilePhotoModal(): void {
        this.isProfilePhotoModalOpened = !this.isProfilePhotoModalOpened;
    }

    @action
    public toggleImageCropperView(isOpen: boolean): void {
        this.isImageCropperVisible = isOpen;
    }

    @observable
    public originalImageFile: File | null = null;

    @action
    public setOriginalImageFile(file: File): void {
        this.originalImageFile = file;
    }

    @computed
    public get country(): undefined | SimpleCountry {
        return this.processWhenDataFetched((self) => self.data.simpleCountries.find((c) => c._id === this.m.countryId));
    }

    @computed
    public get mangopayPersonType(): MangopayPersonType | undefined {
        return isUndefinedOrNull(this.m.activeMangopayInformation)
            ? undefined
            : this.m.activeMangopayInformation.personType;
    }

    @action
    public setIsAddressValid(isValid: boolean) {
        this.isAddressValid = isValid;
    }

    @action
    public addressUpdate(name: string, position: LocationPosition) {
        this.driver.address = name;
        this.driver.position = new Position(position.latitude, position.longitude);
        this.driver.town = name;
    }

    @computed
    public get driver(): Driver {
        return this.m.driver as Driver;
    }

    public getDriverValidations(propertyName: keyof Driver): Array<ValidationError> {
        const driverValidation = this.getValidations("driver")[0];
        return driverValidation?.children?.filter((v) => v.property === propertyName) ?? [];
    }

    public getDriverValidationMessages(propertyName: keyof Driver): string | undefined {
        const result = this.getDriverValidations(propertyName)
            .map((v) => Object.values(v.constraints || {}).join("\n"))
            .join("");
        return result || undefined;
    }

    @observable
    public profilePhotoDeclinationReason = "";

    @action
    public async approveDriverProfilePhoto() {
        const userId = this.m._id;

        try {
            await this.rpcClient.driver.approveProfilePhoto(userId as string);
            await this.fetchData();
            this.profilePhotoDeclinationReason = "";
        } catch (e: any) {
            alert("Oh, something got wrong. :(");
        }
    }

    @action
    public setProfilePhotoDeclinationReason(declinationReason: string): void {
        this.profilePhotoDeclinationReason = declinationReason;
    }

    @action
    public async declineDriverProfilePhoto(): Promise<void> {
        const userId = this.m._id;

        if (!this.profilePhotoDeclinationReason) {
            alert("Please write some declination reason.");
            return;
        }

        try {
            await this.rpcClient.driver.declineProfilePhoto(userId as string, this.profilePhotoDeclinationReason);
            await this.fetchData();
        } catch (e: any) {
            alert("Oh, something got wrong. :(");
        }
    }

    @observable
    public lightboxDocuments?: Array<FilePath>;

    @observable
    public lightboxInitialIndex: number = 0;

    @observable
    public lightboxDescription: string | undefined;

    @observable
    public imageLightboxUrl: string | undefined;

    @action
    public async revealLightbox(fileIds: Array<string>, title: string, index: number = 0) {
        const userId = this.m._id;
        this.lightboxInitialIndex = index;
        this.lightboxDocuments = await this.rpcClient.driver.retrieveFilesPaths(
            userId,
            fileIds,
            API_OPEN_DRIVERS_COMPANY_DOCUMENT_URL,
        );
        this.lightboxDocuments = this.lightboxDocuments.sort((a, b) => a.url.localeCompare(b.url));
        this.lightboxDescription = title;
    }

    @action
    public revealImageLightbox(imageUrl: string | undefined, description: string | undefined) {
        this.imageLightboxUrl = imageUrl;
        this.lightboxDescription = description;
    }

    @action
    public closeLightbox() {
        this.lightboxDocuments = undefined;
        this.lightboxInitialIndex = 0;
        this.lightboxDescription = undefined;
        this.imageLightboxUrl = undefined;
    }

    @observable
    public isProfilePhotoUploading: boolean = false;

    @observable
    public driverProfileImageFormData: FormData = new FormData();

    @action
    public async startProfilePhotoUpload(file: Blob) {
        if (this.isNew) {
            alert("Please fill all required fields and save new user. Then you can upload profile image.");
            return;
        }

        this.isProfilePhotoUploading = true;
        const userId = this.m._id;

        await this.rpcClient.authentication.isUserAuthenticated();
        const { authenticationToken } = this.authenticationStore;

        this.driverProfileImageFormData = new FormData();
        this.driverProfileImageFormData.append(
            "profileImage",
            new File([file], "profileImage.jpg", {
                type: "image/jpeg",
            }),
        );
        this.driverProfileImageFormData.append("userId", userId);

        if (file.size >= MAX_FILE_UPLOAD_SIZE_IN_B) {
            alert("The file can\u0027t be larger than 5 MB.");
            this.isProfilePhotoUploading = false;
            return;
        }

        const request = new XMLHttpRequest();
        request.open("POST", API_UPLOAD_PROFILE_PHOTO_URL, true);
        request.onload = async () => {
            if (request.status === 200 || request.status === 201) {
                this.refreshProfilePhoto();
            } else {
                alert(
                    "Can\u0027t upload. Please try again or contact administrator.\n\nIf you're using Os X Photos app, you need to export the photo before uploading it.",
                );
            }
            this.isProfilePhotoUploading = false;
            this.profilePhotoDeclinationReason = "";

            await this.fetchData();

            this.toggleImageCropperView(false);
            this.toggleProfilePhotoModal();
        };
        request.setRequestHeader("Authorization", `Bearer ${authenticationToken}`);
        request.send(this.driverProfileImageFormData);
    }

    @observable
    public profilePhotoUploadingAttempt = 0;

    @action
    public async refreshProfilePhoto() {
        await this.fetchData();
        this.profilePhotoUploadingAttempt += 1;
    }

    @observable
    public drivingLicenseGrantedAtMonth: number;

    @observable
    public drivingLicenseGrantedAtYear: number;

    @action
    public updateDriverDrivingLicenseGrantedAtMonth(value: number) {
        this.drivingLicenseGrantedAtMonth = value;
        if (!isUndefinedOrNull((this.m.driver as Driver).drivingLicenseGrantedAt)) {
            const date = new Date((this.m.driver as Driver).drivingLicenseGrantedAt as Date);
            this.drivingLicenseGrantedAtYear = date.getUTCFullYear();
        }
        if (
            !isUndefinedOrNull(this.drivingLicenseGrantedAtMonth) &&
            !isUndefinedOrNull(this.drivingLicenseGrantedAtYear)
        ) {
            this.updateDriverDrivingLicenseGrantedAt(
                toJS(this.drivingLicenseGrantedAtMonth),
                toJS(this.drivingLicenseGrantedAtYear),
            );
        }
    }

    @action
    public updateDriverDrivingLicenseGrantedAtYear(value: number) {
        this.drivingLicenseGrantedAtYear = value;
        if (!isUndefinedOrNull((this.m.driver as Driver).drivingLicenseGrantedAt)) {
            const date = new Date((this.m.driver as Driver).drivingLicenseGrantedAt as Date);
            this.drivingLicenseGrantedAtMonth = date.getUTCMonth();
        }
        if (
            !isUndefinedOrNull(this.drivingLicenseGrantedAtMonth) &&
            !isUndefinedOrNull(this.drivingLicenseGrantedAtYear)
        ) {
            this.updateDriverDrivingLicenseGrantedAt(
                toJS(this.drivingLicenseGrantedAtMonth),
                toJS(this.drivingLicenseGrantedAtYear),
            );
        }
    }

    @action
    public async hireDriver() {
        await this.rpcClient.driver.hireDriver(this.m._id);
        this.fetchData();
    }

    @action
    public async activeDriver() {
        await this.rpcClient.driver.activeDriver(this.m._id);
        await this.fetchData();
    }

    @action
    public async declineDriver() {
        await this.rpcClient.driver.declineDriver(this.m._id);
        this.fetchData();
    }

    @action
    public async enableIsSuitableForLuxury(): Promise<void> {
        await this.rpcClient.driver.enableIsSuitableForLuxury(this.m._id);
        await this.fetchData();
    }

    @action
    public async disableIsSuitableForLuxury(): Promise<void> {
        await this.rpcClient.driver.disableIsSuitableForLuxury(this.m._id);
        await this.fetchData();
    }

    @action
    public async enableIsSuitableForPool(): Promise<void> {
        await this.rpcClient.driver.enableIsSuitableForPool(this.m._id);
        await this.fetchData();
    }

    @action
    public async disableIsSuitableForPool(): Promise<void> {
        await this.rpcClient.driver.disableIsSuitableForPool(this.m._id);
        await this.fetchData();
    }

    @action
    public updateDriverDrivingLicenseGrantedAt(month: number, year: number) {
        const date: Date = new Date(year, month, 1, 9);
        if (!isUndefinedOrNull(date)) {
            this.edit((o) => {
                (o.driver as Driver).drivingLicenseGrantedAt = toJS(date);
            });
        }
        this.drivingLicenseGrantedAtMonth = month;
        this.drivingLicenseGrantedAtYear = year;
    }
}
