/* eslint-disable no-param-reassign */
import { API_OPEN_DRIVERS_COMPANY_DOCUMENT_URL } from "@daytrip/legacy-config";
import { UserValidationGroups } from "@daytrip/legacy-enums";
import { DocumentSubject } from "@legacy/domain/DocumentSubject";
import { DynamicDocumentType } from "@legacy/domain/DocumentTypes";
import type { FilePath } from "@legacy/domain/FilePath";
import { PayoutType } from "@legacy/domain/PayoutType";
import type { SimpleCountry } from "@legacy/domain/SimpleCountry";
import type { SimpleLocation } from "@legacy/domain/SimpleLocation";
import type { AutomaticEmailAttempt } from "@legacy/models/AutomaticEmailAttempt";
import { Document } from "@legacy/models/Document";
import { DocumentType } from "@legacy/models/DocumentType";
import type { Driver } from "@legacy/models/Driver";
import { DriversCompany } from "@legacy/models/DriversCompany";
import { BillingInformation, User } from "@legacy/models/User";
import { isUndefinedOrNull } from "@legacy/utils";
import autobind from "autobind-decorator";
import { plainToClass } from "class-transformer";
import { action, computed, observable, reaction } from "mobx";

import { routes } from "../../container";
import { globalManagementLogger } from "../../global-logger";
import { DocumentOperator } from "../../operators/DocumentOperator";
import { DocumentTypeOperator } from "../../operators/DocumentTypeOperator";
import { DriversCompanyOperator } from "../../operators/DriversCompanyOperator";
import { PageStore } from "../../stores/PageStore";
import { FetchDataStatus } from "../../utils/FetchDataStatus";
import { throwIfInvalidUserPhoneNumber } from "../../utils/validateUserPhoneNumber";

import type { DriversCompanyRouter } from "./DriversCompanyRouter";

@autobind
export class DriversCompanyStore extends PageStore<DriversCompanyRouter, {}> {
    @observable
    public driversCompanyOperator: DriversCompanyOperator;

    public canCreateHyperwalletAccount: boolean;

    public canCreateStripeDriverAccount: boolean;

    @observable
    public simpleLocations?: Array<SimpleLocation>;

    @observable
    public countries: Array<SimpleCountry> | undefined;

    @observable
    public documentTypeOperators?: Array<DocumentTypeOperator>;

    @observable
    public hiredByUser?: User;

    @observable
    public authenticationToken: string;

    @observable
    public isSimpleLocationsLoading: boolean = false;

    @observable
    public automaticEmailAttempts: Array<AutomaticEmailAttempt> = [];

    public isNewDriver = false;

    @action
    public async initDriverCompanyOperator(): Promise<void> {
        this.driversCompanyOperator = new DriversCompanyOperator({
            modelConstructor: User,
            data: {
                simpleLocations: this.simpleLocations,
                simpleCountries: this.countries,
                managers: [],
            },
            modules: null,
            onFetchData: async (operator: DriversCompanyOperator) => {
                let { userId } = this.pageRouter;
                const { userEmail } = this.pageRouter;
                const { userJWT, isRegionalManager, hasInternalManagementPermission, isCustomerSupport } =
                    this.authenticationStore;
                const currentUserId = userJWT?.userId;

                if (!hasInternalManagementPermission && !isCustomerSupport && !isRegionalManager) {
                    if (userId && userId !== currentUserId) {
                        return FetchDataStatus.Unauthorized;
                    }
                    userId = currentUserId;
                }

                try {
                    if (userId) {
                        operator.model = plainToClass(User, await this.rpcClient.user.retrieveUser(userId));
                    } else if (userEmail) {
                        operator.model = plainToClass(User, await this.rpcClient.user.retrieveUserByEmail(userEmail));
                    } else {
                        operator.model.driversCompany = new DriversCompany();

                        this.isNewDriver = true;
                        operator.edit(() => {});
                    }

                    if (hasInternalManagementPermission || isRegionalManager || isCustomerSupport) {
                        operator.data.managers = await this.rpcClient.user.getSimpleRegionalManagersByCountryId(
                            operator.model.countryId,
                        );
                    }

                    this.canCreateHyperwalletAccount = operator.model.hyperwalletInformation == null;
                    this.canCreateStripeDriverAccount = operator.model.stripeInformation == null;
                } catch (e: any) {
                    return FetchDataStatus.NotFound;
                }

                return FetchDataStatus.Success;
            },
            isDataFetchedCondition: (operator: DriversCompanyOperator) =>
                operator.model != undefined &&
                operator.data.simpleLocations != undefined &&
                operator.data.simpleCountries != undefined,
            beforeSave: async (user: User) => {
                if (this.driversCompanyOperator.m.payoutType === undefined) {
                    alert("Payout type is required");
                    throw new Error("Payout type is required");
                }
                if (user.payoutType === PayoutType.Hyperwallet && this.canCreateHyperwalletAccount) {
                    if (!user.firstName || !user.lastName || !user.email || !user.countryId || !user.phoneNumber) {
                        alert(
                            "To add Hyperwallet as Payout Type you need to fill the fields: First name, Last name, Email, Country, Phone Number",
                        );
                        throw new Error("Missing fields for hyperwallet account creation");
                    }
                }

                if (user.payoutType === PayoutType.Stripe && this.canCreateStripeDriverAccount) {
                    if (!user.email || !user.countryId) {
                        alert("To add Stripe as Payout Type you need to fill the fields: Email and Country");
                        throw new Error("Missing fields for stripe connected account creation");
                    }
                }

                throwIfInvalidUserPhoneNumber(user.phoneNumber);
                throwIfInvalidUserPhoneNumber(user.whatsappNumber, true);
            },
            onSave: async (user: User & { driver: Driver }) => {
                if (!this.driversCompanyOperator.isAddressValid) {
                    throw new Error("Provided hometown is not valid");
                }
                if (!this.isNewDriver) {
                    try {
                        const oldUser = await this.rpcClient.user.retrieveUser(user._id);

                        if (oldUser.payoutType !== user.payoutType && user.billingInformation.length) {
                            const activeBillingInformationIndex = user.billingInformation.findIndex(
                                (billingInformation: BillingInformation) => billingInformation.isActive,
                            );
                            const activeBillingInformation = user.billingInformation[activeBillingInformationIndex];
                            if (activeBillingInformation && activeBillingInformation.payoutType !== user.payoutType) {
                                if (isUndefinedOrNull(user.payoutType)) {
                                    throw new Error("Payout type is missing");
                                }
                                user.billingInformation[activeBillingInformationIndex].payoutType = user.payoutType;
                            }
                        }

                        if (this.authenticationStore.isDriversCompany) {
                            await this.rpcClient.driver.selfUpdateTransporterUser(user._id, user);
                        } else {
                            await this.rpcClient.user.updateUser(user._id, user, {
                                validationGroups: [UserValidationGroups.PROFILE, UserValidationGroups.DRIVER_COMPANY],
                            });
                        }

                        this.driversCompanyOperator.edit((u) =>
                            u.version != undefined ? u.version++ : (u.version = 0),
                        );

                        if (user.payoutType === PayoutType.Hyperwallet && this.canCreateHyperwalletAccount) {
                            await this.createHyperwalletUser(user);
                        }

                        if (user.payoutType === PayoutType.Stripe && this.canCreateStripeDriverAccount) {
                            await this.createStripeConnectedAccount(user);
                        }

                        if (user.hyperwalletInformation?.token && oldUser.email !== user.email) {
                            // update hyperwallet user email
                            await this.rpcClient.hyperwallet.updateHyperWalletUser(user._id);
                        }
                    } catch (e: any) {
                        globalManagementLogger.error(e);
                        throw new Error(
                            e.message ||
                                "Unable to save company profile. It could be the result of someone updated this profile before you while you was on this page. Please, reload the page and try again.",
                        );
                    }
                } else {
                    try {
                        user._id = await this.rpcClient.user.createUser(user);
                    } catch (err: any) {
                        let statusCode;
                        try {
                            const errorObj = JSON.parse(err.message);
                            statusCode = errorObj.statusCode;
                        } catch (e) {
                            throw err;
                        }

                        if (statusCode === 403 && this.authenticationStore.isRegionalManager) {
                            throw new Error(
                                e.message ||
                                    "You do not have the permissions to add a drivers company within this country. Please contact your manager to allow access. Also, make sure to log out and back in again after your new regional permissions have been added",
                            );
                        }
                        throw err;
                    }

                    if (user.payoutType === PayoutType.Hyperwallet) {
                        await this.createHyperwalletUser(user);
                    }

                    if (user.payoutType === PayoutType.Stripe) {
                        await this.createStripeConnectedAccount(user);
                    }

                    this.pageRouter.openCreatedUser(user._id);
                    this.isNewDriver = false;
                }
            },
            validateOptions: {
                skipMissingProperties: true,
                groups: [UserValidationGroups.PROFILE, UserValidationGroups.DRIVER_COMPANY],
            },
        });
    }

    private async createHyperwalletUser(user: User) {
        try {
            const hyperwalletResponse = await this.rpcClient.hyperwallet.createHyperWalletUser(user._id);
            if (hyperwalletResponse) {
                this.driversCompanyOperator.edit((model) => {
                    model.hyperwalletInformation = hyperwalletResponse.hyperwalletInformation;
                });
                this.canCreateHyperwalletAccount = false;
            }
        } catch (error) {
            // eslint-disable-next-line no-alert
            alert(`Hyperwallet user cannot be created. Reason: ${error}`);
        }
    }

    private async createStripeConnectedAccount(user: User) {
        try {
            const response = await this.rpcClient.stripe.createStripeDriverAccount(user._id);
            if (response) {
                this.driversCompanyOperator.edit((model) => {
                    model.stripeInformation = response.stripeInformation;
                });
                this.canCreateStripeDriverAccount = false;
            }
        } catch (error) {
            // eslint-disable-next-line no-alert
            alert(`Stripe connected account cannot be created. Reason: ${error}`);
        }
    }

    @action
    public async onFetchData() {
        await this.fetchContent();
    }

    @action
    public async fetchContent() {
        this.simpleLocations = [];
        this.countries = await this.rpcClient.content.retrieveSimpleCountries({});

        if (this.driversCompanyOperator == undefined) {
            await this.initDriverCompanyOperator();
        }
        await this.driversCompanyOperator.fetchData();

        if (
            this.driversCompanyOperator.model.driversCompany === undefined &&
            this.driversCompanyOperator.model.driver
        ) {
            this.pageRouter.routerStore.replace({
                pathname: routes.driverProfile,
                search: `?userId=${this.driversCompanyOperator.model._id}`,
            });
            return;
        }

        if (
            this.driversCompanyOperator.model.driversCompany !== undefined &&
            this.driversCompanyOperator.model.driversCompany.originLocationIds.length > 0
        ) {
            this.simpleLocations = await this.rpcClient.content.retrieveSimpleLocations({
                ids: this.driversCompanyOperator.model.driversCompany.originLocationIds,
            });

            this.driversCompanyOperator.data.simpleLocations = this.simpleLocations;
        }

        await this.fetchDocuments();

        this.authenticationToken = this.authenticationStore.authenticationToken;

        if (
            this.driversCompanyOperator.driversCompany.hiredByUserId &&
            this.authenticationStore.hasInternalManagementPermission
        ) {
            try {
                this.hiredByUser = await this.rpcClient.user.retrieveUserObjectById(
                    this.driversCompanyOperator.driversCompany.hiredByUserId,
                );
            } catch (error) {
                globalManagementLogger.error(
                    `Unable to find hiredByUser with id ${this.driversCompanyOperator.driversCompany.hiredByUserId}`,
                );
                this.hiredByUser = undefined;
            }
        }
    }

    @action
    public async simpleLocationsFetch(): Promise<void> {
        this.isSimpleLocationsLoading = true;
        this.simpleLocations = await this.rpcClient.content.retrieveSimpleLocations({
            isDestination: true,
        });
        this.isSimpleLocationsLoading = false;
    }

    @action
    public async deleteUser() {
        try {
            const userId = this.driversCompanyOperator.m._id;
            await this.rpcClient.driversCompany.deleteUser(userId);
            this.pageRouter.openUsersAfterDeletion();
        } catch (e: any) {
            alert("Something went wrong during deletion.");
            globalManagementLogger.error(e);
        }
    }

    public isDataFetched(): this is DriversCompanyStore & DriversCompanyStoreDataFetched {
        return (
            this.simpleLocations !== undefined &&
            this.countries !== undefined &&
            this.documentTypeOperators !== undefined &&
            this.driversCompanyOperator.isDataFetched() &&
            this.driversCompanyOperator.driversCompany !== undefined
        );
    }

    public countryIdReaction = reaction(
        () => (this.driversCompanyOperator != undefined ? this.driversCompanyOperator.m.countryId : undefined),
        (countryId) => {
            if (countryId != undefined) {
                this.fetchDocuments();
            }
        },
    );

    private async fetchDynamicFormDocuments() {
        const ids = this.driversCompanyOperator.m.driversCompany?.documents.map((doc) => doc.documentTypeId);

        if (!ids || !ids.length) {
            return [];
        }

        const documentTypesForDriver = plainToClass(
            DocumentType,
            await this.rpcClient.document.retrieveDocumentTypes({ ids }),
        );

        return documentTypesForDriver.filter(
            (docType) => docType.dynamicDocumentType === DynamicDocumentType.COOPERATION_AGREEMENT,
        );
    }

    private async fetchRegularDocuments() {
        if (!this.driversCompanyOperator.m?.countryId || this.driversCompanyOperator.driversCompany === undefined) {
            return [];
        } else {
            const documentTypesForCompany = plainToClass(
                DocumentType,
                await this.rpcClient.document.retrieveDocumentTypes({
                    countryIds: [this.driversCompanyOperator.m.countryId],
                    subject: DocumentSubject.DriversCompany,
                }),
            );

            return documentTypesForCompany;
        }
    }

    @action
    public async fetchDocuments() {
        const [regularDocuments, dynamicFormDocuments] = await Promise.all([
            this.fetchRegularDocuments(),
            this.fetchDynamicFormDocuments(),
        ]);

        const allDocuments = [...regularDocuments, ...dynamicFormDocuments];

        this.documentTypeOperators = this.mapDocTypeToOperator(allDocuments);
    }

    private mapDocTypeToOperator(documentTypes: DocumentType[]) {
        return documentTypes.map((documentType) => {
            let document = this.driversCompanyOperator.driversCompany.documents.find(
                (d) => d.documentTypeId === documentType._id && isUndefinedOrNull(d.deletedAt),
            );

            if (documentType.dynamicDocumentType === DynamicDocumentType.COOPERATION_AGREEMENT) {
                const activeBillingInfo = this.driversCompanyOperator.m.billingInformation.find((bi) => bi.isActive);

                if (activeBillingInfo) {
                    document = this.driversCompanyOperator.driversCompany.documents.find(
                        (doc) => doc._id === activeBillingInfo.documentId,
                    );
                } else {
                    const allCAs = this.driversCompanyOperator.driversCompany.documents.filter(
                        (doc) => doc.documentTypeId === documentType._id,
                    );
                    // if driversCompany has no Active BI, show latest CA
                    document = allCAs.at(-1);
                }
            }

            const dto = new DocumentTypeOperator({
                modelConstructor: DocumentType,
                model: documentType,
                documentOperator:
                    document &&
                    new DocumentOperator({
                        modelConstructor: Document,
                        model: document,
                        data: null,
                        modules: null,
                    }),
                data: null,
                modules: null,
                userId: this.driversCompanyOperator.m._id,
                onDriverDocumentUploaded: async () => {
                    this.driversCompanyOperator.cancelEdit();
                    await this.fetchDocuments();
                },
                onDriversCompanyDocumentUploaded: async () => {
                    this.driversCompanyOperator.cancelEdit();
                    await this.fetchDocuments();
                },
            });

            return dto;
        });
    }

    @observable
    public lightboxDocuments?: Array<FilePath>;

    @observable
    public lightboxInitialIndex: number = 0;

    @observable
    public lightboxDescription: string | undefined;

    @action
    public async revealLightbox(fileIds: Array<string>, title: string, index: number = 0) {
        const userId = this.driversCompanyOperator.model._id;
        this.lightboxInitialIndex = index;
        const lightboxDocuments = await this.rpcClient.driver.retrieveFilesPaths(
            userId,
            fileIds,
            API_OPEN_DRIVERS_COMPANY_DOCUMENT_URL,
        );
        this.lightboxDocuments = lightboxDocuments.sort((a, b) => a.url.localeCompare(b.url));
        this.lightboxDescription = title;
    }

    @observable
    public imageLightboxUrl: string | undefined;

    @action
    public revealImageLightbox(imageUrl: string | undefined, description: string | undefined) {
        this.imageLightboxUrl = imageUrl;
        this.lightboxDescription = description;
    }

    @action
    public closeLightbox() {
        this.lightboxDocuments = undefined;
        this.lightboxInitialIndex = 0;
        this.lightboxDescription = undefined;
        this.imageLightboxUrl = undefined;
    }

    public hireReason?: string;

    @action
    public async hireDriver() {
        await this.rpcClient.driversCompany.hireDriversCompany(this.driversCompanyOperator.m._id, this.hireReason);
        this.driversCompanyOperator.fetchData();
    }

    @action
    public async activeDriver() {
        await this.rpcClient.driversCompany.activeDriversCompany(this.driversCompanyOperator.m._id);
        this.driversCompanyOperator.fetchData();
    }

    @action
    public async declineDriver() {
        await this.rpcClient.driversCompany.declineDriversCompany(this.driversCompanyOperator.m._id);
        this.driversCompanyOperator.fetchData();
    }

    @action
    public cancelEdit() {
        this.driversCompanyOperator.cancelEdit();

        if (this.isNewDriver) {
            window.history.back();
        }
    }

    public profileId: string = "profileSection";

    @computed
    public get isOwner(): boolean {
        return this.authenticationStore.userJWT?.userId === this.driversCompanyOperator.m._id;
    }
}

export interface DriversCompanyStoreDataFetched {
    user: User;
    simpleLocation: Array<SimpleLocation>;
    countries: Array<SimpleCountry>;
    documentTypeOperators: Array<DocumentTypeOperator>;
}
