import { UserValidationGroups } from "@daytrip/legacy-enums";
import { DocumentSubject } from "@legacy/domain/DocumentSubject";
import { SimpleCountry } from "@legacy/domain/SimpleCountry";
import { Document } from "@legacy/models/Document";
import { DocumentType } from "@legacy/models/DocumentType";
import { Driver } from "@legacy/models/Driver";
import { User } from "@legacy/models/User";
import { isUndefinedOrNull } from "@legacy/utils";
import autobind from "autobind-decorator";
import { plainToClass } from "class-transformer";
import { action, observable } from "mobx";

import { globalManagementLogger } from "../../global-logger";
import { CompanyDriverOperator } from "../../operators/CompanyDriverOperator";
import { DocumentOperator } from "../../operators/DocumentOperator";
import { DocumentTypeOperator } from "../../operators/DocumentTypeOperator";
import { PageStore } from "../../stores/PageStore";
import { throwIfInvalidUserPhoneNumber } from "../../utils/validateUserPhoneNumber";

import { CompanyDriversPageRouter } from "./CompanyDriversPageRouter";

@autobind
export class CompanyDriversPageStore extends PageStore<CompanyDriversPageRouter, {}> {
    @observable
    public driversCompany: User;

    @observable
    public companyDriverOperators: Array<CompanyDriverOperator>;

    @observable
    public driversCount: number = 0;

    @observable
    public simpleCountries: Array<SimpleCountry>;

    @observable
    public existingUser?: boolean;

    @action
    public async validateEmail(operator: CompanyDriverOperator) {
        if (
            operator.editedModel &&
            operator.editedModel.email &&
            (await this.rpcClient.user.checkIfUserWithEmailExists(operator.editedModel.email, operator.editedModel._id))
        ) {
            this.existingUser = true;
        } else {
            this.existingUser = undefined;
        }
    }

    @action
    public addDriver(): void {
        const newUser = new User();
        newUser.driver = new Driver();
        newUser.driver.driversCompanyUserId = this.driversCompany._id;
        newUser.email = undefined;

        const newDriver = new CompanyDriverOperator({
            modelConstructor: User,
            model: plainToClass(User, newUser),
            modules: null,
            data: {
                simpleCountries: this.simpleCountries,
            },
            onSave: async (user: User & { driver: Driver }) => {
                user.countryId = this.driversCompany.countryId;

                user._id = await this.rpcClient.user.createUser(user);
                await this.fetchCompanyDrivers();
            },
            isNew: true,
            validateOptions: { skipMissingProperties: true },
        });

        newDriver.edit(() => {});
        this.companyDriverOperators.unshift(newDriver);
    }

    @action
    public async deleteDriver(id: string) {
        try {
            const companyDriverOperator = this.companyDriverOperators.find((co) => co.m._id == id);

            if (companyDriverOperator != undefined) {
                await this.rpcClient.driver.deleteUser(id);
                this.companyDriverOperators = this.companyDriverOperators.filter((co) => co.m._id != id);
            }
        } catch (e: any) {
            alert("Something went wrong during deletion.");
            // tslint:disable-next-line:no-console
            console.error(e);
        }
    }

    // data fetching

    @action
    public async onFetchData(): Promise<void> {
        await this.fetchContent();
        await this.fetchCompanyDrivers();
    }

    @action
    public async fetchContent(): Promise<void> {
        this.driversCompany = this.pageRouter.userId
            ? await this.rpcClient.user.retrieveUser(this.pageRouter.userId)
            : null;
        this.simpleCountries = await this.rpcClient.content.retrieveSimpleCountries({});
    }

    @action
    public async fetchCompanyDrivers(): Promise<void> {
        const drivers = await this.rpcClient.driversCompany.retrieveCompanyDrivers(this.pageRouter.userId);
        const documentTypes = plainToClass(
            DocumentType,
            await this.rpcClient.document.retrieveDocumentTypes({
                subject: DocumentSubject.CompanyDriver,
                countryIds: drivers.map((d) => d.countryId!),
            }),
        );

        const companyDriverOperators: Array<CompanyDriverOperator> = [];

        drivers
            .filter(
                (driver) =>
                    isUndefinedOrNull(this.pageRouter.search) ||
                    driver.firstName.includes(this.pageRouter.search) ||
                    driver.lastName.includes(this.pageRouter.search) ||
                    (driver.email != undefined && driver.email.includes(this.pageRouter.search)) ||
                    driver.phoneNumber.includes(this.pageRouter.search),
            )
            .forEach((d) => {
                d.driver = plainToClass(Driver, d.driver);

                companyDriverOperators.push(
                    new CompanyDriverOperator({
                        modelConstructor: User,
                        model: d,
                        modules: null,
                        data: {
                            simpleCountries: this.simpleCountries,
                        },
                        onFetchData: async (operator: CompanyDriverOperator) => {
                            operator.model = plainToClass(
                                User,
                                await this.rpcClient.driversCompany.retrieveCompanyDriver(
                                    this.pageRouter.userId,
                                    operator.m._id,
                                ),
                            );

                            if (operator.isEdited()) {
                                operator.editedModel = operator.model;
                            }
                        },
                        beforeSave(user: User & { driver: Driver }) {
                            throwIfInvalidUserPhoneNumber(user.phoneNumber);
                            throwIfInvalidUserPhoneNumber(user.whatsappNumber, true);
                        },
                        onSave: async (user: User & { driver: Driver }) => {
                            const thisOperator = this.companyDriverOperators.find(
                                (cdo) => cdo.m._id === user._id,
                            ) as CompanyDriverOperator;
                            if (!thisOperator.isAddressValid) {
                                throw new Error("Provided hometown is not valid");
                            }
                            try {
                                if (
                                    this.authenticationStore.isCompanyDriver ||
                                    this.authenticationStore.isDriversCompany
                                ) {
                                    await this.rpcClient.driver.selfUpdateTransporterUser(user._id, user);
                                } else {
                                    await this.rpcClient.user.updateUser(user._id, user, {
                                        validationGroups: [UserValidationGroups.PROFILE, UserValidationGroups.DRIVER],
                                    });
                                }
                                const thisOperator = this.companyDriverOperators.find(
                                    (cdo) => cdo.m._id === d._id,
                                ) as CompanyDriverOperator;
                                thisOperator.fetchData();
                            } catch (e: any) {
                                globalManagementLogger.error(e);
                                throw e;
                            }
                        },
                        documentTypeOperators: documentTypes.map((docType: DocumentType) => {
                            const document = (d.driver as Driver).documents.find(
                                (doc) => doc.documentTypeId == docType._id && isUndefinedOrNull(doc.deletedAt),
                            );

                            const dto = new DocumentTypeOperator({
                                modelConstructor: DocumentType,
                                model: docType,
                                documentOperator:
                                    document != undefined
                                        ? new DocumentOperator({
                                              modelConstructor: Document,
                                              model: document,
                                              data: null,
                                              modules: null,
                                          })
                                        : undefined,
                                data: null,
                                modules: null,
                                userId: d._id,
                                isMangopayInformationsFilled: false,
                                onDriverDocumentUploaded: async () => {
                                    const thisOperator = this.companyDriverOperators.find(
                                        (cdo) => cdo.m._id === d._id,
                                    ) as CompanyDriverOperator;

                                    thisOperator.cancelEdit();
                                    await this.fetchCompanyDrivers();
                                },
                                onDriversCompanyDocumentUploaded: async () => {
                                    const thisOperator = this.companyDriverOperators.find(
                                        (cdo) => cdo.m._id === d._id,
                                    ) as CompanyDriverOperator;

                                    thisOperator.cancelEdit();
                                    await this.fetchCompanyDrivers();
                                },
                            });

                            return dto;
                        }),
                        validateOptions: { skipMissingProperties: true },
                    }),
                );
            });

        this.companyDriverOperators = companyDriverOperators;
    }

    public isDataFetched(): this is CompanyDriversPageStore & CompanyDriversPageStoreDataFetched {
        return !isUndefinedOrNull(this.driversCompany) && !isUndefinedOrNull(this.companyDriverOperators);
    }
}

export interface CompanyDriversPageStoreDataFetched {
    driversCompany: User;
    simpleCountries: Array<SimpleCountry>;
    companyDriverOperators: Array<CompanyDriverOperator>;
}
